<template>
  <div>
    <div class="page-header">
          <div class="row align-items-center header">
            <div class="col-9">
              <div class="title">
                <router-link to="/admin/video-builder">Video Section Builder</router-link>/
                <span> {{ $route.params.id }} </span>
              </div>
            </div>
            <div class="col d-flex justify-content-end">
              <b-btn variant="danger" @click="saveSection">Save Changes</b-btn>
            </div>
          </div>
        </div>
  
        <div class="page-content">
          
            <b-row>
                <b-col cols="8">
                    <div class="d-flex flex-column">
                      <div v-if="section.rows.length > 0" >
                        <b-row v-for="(row, rowIndex) of section.rows" :key="row.title" class="section-row">
                          <b-col cols="12" class="d-flex justify-content-between">
                            <div class="row-meta">
                            {{ row.title }} <br>
                            <span class="secondary">{{ row.type }} row type
                              {{ row.automated? ' (Automated)' : '(Curated)' }}
                            </span>
                            </div>
                            <!-- <div class="row-settings" @click="editRow(row, rowIndex)">
                              <i class="fas fa-ellipsis-h"></i>
                            </div> -->
                          </b-col>

                          <draggable v-if="!row.automated" :list="row.items"
                              :options="{group:{ name:'card', put:['newCard', 'card'], pull:false}}"
                              class="col-12 d-flex flex-wrap" :class="row.type">
                                <div v-for="(resource, resourceIdx) of row.items" :key="resource._id" class="m-1">
                                  <div class="m-1 row-item" v-b-tooltip.hover :title="resource.title">
                                    <b-img v-if="resource.image" :src="resource.image" width="80" height="80"></b-img>
                                    
                                    <div class="row-item-meta">
                                      <div class="title"> {{ resource.title }}</div>
                                      <i class="fas fa-times rm-icon" @click="removeItem(rowIndex, resourceIdx)"></i>
                                    </div>
                                  </div>
                                </div>
                            </draggable>
                            <div v-else class="col-12 d-flex flex-column" >
                              <div>
                                <label for="feedUrl"> <u>Feed URL <b>(Mobile)</b> </u> </label>
                                <div class="d-flex align-items-center">
                                  <b-form-input id="feedUrl" v-model="row.feedUrl" placeholder="https://api.lamusica.com..." :disabled="disableFeedEdit" size="sm" class="mx-1"></b-form-input>
                                  <b-btn size="sm" class="mx-1" variant="danger" @click="disableFeedEdit=!disableFeedEdit">
                                    <b-icon-pencil-fill></b-icon-pencil-fill>
                                  </b-btn>
                                </div>
                              </div>

                              <div >
                                <label for="feedUrl"> <u>Feed URL <b>(TVs & OTTs)</b> </u> </label>
                                <div class="d-flex align-items-center">
                                  <b-form-input id="feedUrl" v-model="row.ottFeedUrl" placeholder="https://api.lamusica.com..." :disabled="disableOttFeedEdit" size="sm" class="mx-1"></b-form-input>
                                  <b-btn size="sm" class="mx-1" variant="danger" @click="disableOttFeedEdit=!disableOttFeedEdit">
                                    <b-icon-pencil-fill></b-icon-pencil-fill>
                                  </b-btn>
                                </div>
                              </div>
                              
                            </div>
                        </b-row>                        

                      </div>

                      <!-- <b-btn @click="addRow">Add A Row</b-btn> -->
                    </div>
                </b-col>

                <b-col>
                  <b-row no-gutters>
                      <b-col cols="8" class="d-flex">
                        <b-form-input size="sm" v-model="searchResourceQuery" placeholder="Search Resource" 
                        @input="searchResource"
                        class="my-2"></b-form-input>
                        <b-btn size="sm" variant="danger" class="ico-btn" @click="resetSearch">
                          <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
                        </b-btn>
                      </b-col>
                      <b-col cols="4" class="px-2">
                        <b-form-select v-model="selected" 
                        :options="options" 
                        @change="resourceUpdate"
                        size="sm" class="my-2"></b-form-select>
                      </b-col>
                      <b-col cols="12" class="d-flex justify-content-center" v-if="selectionPanelLoading">
                        <b-spinner variant="danger" label="loading..."></b-spinner>
                      </b-col>
                      <b-col cols="12" v-else>

                        <draggable v-if="latestSelectedResource.length > 0" class="selection-panel d-flex flex-wrap"
                            :list="latestSelectedResource"
                            :move="addingNewCard"
                            @start="showAlert=true"
                            :options="{group:{ name:'newCard',  pull:'clone', put:false }}"
                            >
                            <div v-for="resource of latestSelectedResource" :key="resource._id">
                              <div class="m-1 selection-panel-item" v-b-tooltip.hover :title="resource.title">
                                <b-img width="80" height="80" 
                                  :src="resource.image"></b-img>
                                <div class="title"> {{ resource.title }} </div>
                              </div>
                            </div>
                        </draggable>

                        <div v-else class="d-flex justify-content-center">
                          No Cards
                        </div>

                      </b-col>
                    </b-row>
                </b-col>
              
            </b-row>
            
            <!-- 
            <b-modal id="add-row-modal" :title="selectedRowValues.title" @ok="saveRow">
              <b-form-input class="m-1" v-model="selectedRowValues.title" placeholder="Row Title"></b-form-input>
              <b-form-select v-model="selectedRowValues.type" :options="options"></b-form-select> 
              <b-form-checkbox class="m-1" v-model="selectedRowValues.automated"> Automated </b-form-checkbox>
              <b-form-input class="m-1" v-if="selectedRowValues.automated" v-model="selectedRowValues.feedUrl" placeholder="Url endpoint for automated data"></b-form-input>
            </b-modal> -->
        </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
/**
 * 3 types of row; square, portrait, landscape
 */

export default {
  components:{
    draggable
  },
  data(){
    return{
      searchResourceQuery: '',
      selected: 'video',
      showAlert: false,
      options: [
        { value: 'video', text: 'Editorial Videos' },
        { value: 'show', text: 'Shows' },
        // { value: 'moment', text: 'Moments' },
        { value: 'show-episode', text: 'Show Episodes' },
      ],
      section:{
        rows:[
          {
            idx:0,
            title:'Shorts',
            type: 'moment',
            automated:true,
            feedUrl: 'https://api-dev.lamusica.com/mobile/v1/moments',
            ottFeedUrl: 'https://api-dev.lamusica.com/tv/moments',
            items:[]
          },
          {
            idx:1,
            title:'Featured',
            type: 'mixed',
            automated:false,
            feedUrl: '',
            items:[]
          },
          {
            idx:2,
            title:'Shows',
            type: 'show',
            automated:false,
            feedUrl: '',
            items:[]
          },
        ],
        published:true
      },
      latestSelectedResource:[],
      latestCards:[],
      selectedRowValues:{
        idx:-1,
        title:'',
        type:'',
        automated:false,
        feedUrl:'',
        items:[]
      },
      modalFlag:'new',
      selectionPanelLoading: false,
      disableFeedEdit: true,
      disableOttFeedEdit: true
    }
  },
  mounted(){
    this.getLatestCards()
    this.getSectionData()
    // this.getLatestUpdatedVideos()
  },
  methods:{
    async getSectionData(){
      const response = await this.$http.get('/video-section')
      if(response.success && response.data){
        this.section = response.data
        this.getCardDataForSection()
      }
    },
    async saveSection(){
      const response = await this.$http.post('/video-section', {section: this.section})
      console.log('response', response);
      if(response.success){
        this.$notify({
            group: 'notifications',
            title: 'Section Updated',
            text: '',
            type: 'success'
          })
        if(response.data && response.data.insertedId){
          this.section._id = response.data.insertedId
        }
      }
    },
    async resourceUpdate(){
      this.getLatestCards()
    },
    async getLatestCards(){
      const cards = await this.$http.get('/cards', {params: {type: this.selected, size: 'all', q: '', page_size:100}})
      this.latestCards = cards.data
      switch(this.selected){
        case 'video':
          await this.formatVideoResponse(this.latestCards)
          break;
        case 'show':
        await this.formatShowResponse(this.latestCards)
          break;
        case 'moment':
        await this.formatMomentResponse(this.latestCards)
        break;
        case 'show-episode':
        await this.formatShowEpisodeResponse(this.latestCards)
          break;
      }
    },
    formatVideoResponse(videoArray){
      const latestVideos = videoArray.map( (v) =>({
        _id: v._id,
        id: v.id,
        title: v.titleEnglish,
        type:'video',
        image: v.img[0]['750x750'] || 'https://placehold.co/200'
      }))
      this.latestSelectedResource = latestVideos
    },
    formatShowEpisodeResponse(episodes){
      const latestEpisodes = episodes.map( (ep) =>({
        _id: ep._id,
        id: ep.id,
        title: ep.titleEnglish,
        type:'show-episode',
        image:  ep.img[0]['750x750'] || 'https://placehold.co/200'
      }))
      this.latestSelectedResource = latestEpisodes
    },
    formatMomentResponse(momentArray){
      const latestMoments = momentArray.map( (m) =>({
        _id: m._id,
        id: m.id,
        title: m.titleEnglish,
        type:'moment',
        image: m.img[0]['750x750'] || 'https://placehold.co/200'
      }))
      this.latestSelectedResource = latestMoments
    },
    formatShowResponse(showArray){
      const latestShows = showArray.map( (s) =>({
        _id: s._id,
        id: s.id,
        title: s.titleEnglish,
        type:'show',
        image: s.img[0]['750x750'] || 'https://placehold.co/200'
      }))
      this.latestSelectedResource = latestShows
    },
    addingNewCard(evt){
      const elType = evt.draggedContext.element.type
      const allClasses = evt.to.classList
      const cls = allClasses[0]
      // disallow placing item in self
      if(cls==='selection-panel'){
        return false
      }

      if(allClasses.contains('mixed')){
        if(elType==='moment'){
          if(this.showAlert){
          this.$notify({
              group: 'notifications',
              title: 'Moments Not Allowed',
              text: 'Moments are not allowed in this row',
              type: 'error'
            })
            this.showAlert=false
          }
          return false
        }
      }else if(!allClasses.contains(elType)){
        if(this.showAlert){
          this.$notify({
              group: 'notifications',
              title: 'Content Type Mismatch',
              text: 'Content type and row type doesn\'t match',
              type: 'error'
            })
            this.showAlert=false
        }
        return false
      }
      
      let flag = false
      
      evt.relatedContext.list.forEach((item) => {
        if (item._id === evt.draggedContext.element._id) {
          flag = true
        }
      })

      if (flag) {
        if(this.showAlert){
          this.$notify({
              group: 'notifications',
              title: 'Duplicate card error!',
              text: '',
              type: 'error'
            })
            this.showAlert=false
        }
        return false
      }
    },
    removeItem(rowIndex, itemIndex){
      console.log('removeItem', rowIndex, itemIndex);
      this.section.rows[rowIndex].items.splice(itemIndex, 1)
    },
    resetSearch(){
      this.searchResourceQuery=''
      this.resourceUpdate()
    },
    async searchResource(){
      // this.selectedRowValues
      // this.searchResourceQuery
      
      if(this.searchResourceQuery ===''){
        return
      }
      this.selectionPanelLoading = true
      let searchResult= []
      console.log(`sqer /video-section/search?q=${this.searchResourceQuery}&type=title`);

      searchResult= await this.$http.get(`/video-section/search?q=${this.searchResourceQuery}&type=${this.selected}`)

      switch (this.selected) {
        case 'video':
          if(searchResult.success){
            this.formatVideoResponse(searchResult.data)
          }
          break;
        case 'show':
          if(searchResult.success){
            this.formatShowResponse(searchResult.data)
          }
          break;
        case 'moment':
          if(searchResult.success){
            this.formatMomentResponse(searchResult.data)
          }
          break;
      }
      console.log('sres', searchResult);
      this.selectionPanelLoading = false
    },
  }
}
</script>

<style scoped>
.section-row{
    background-color: white;
    border: 2px white solid;
    margin: 10px;
    padding: 10px;
}
.selection-panel-item{
  max-width: 80px;
  max-height: 100px;
}

.selection-panel-item .title{
  font-size: 10px;
  display: block;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.row-meta{
  font-weight: bold;
}

.row-meta .secondary{
  font-weight: normal;
  color: red;
}

.row-settings{
  cursor: pointer;
}

.row-item{
  max-width: 80px;
  max-height: 130px;
}

.row-item-meta{
  display: flex;
}

.row-item .row-item-meta .title{
  font-size: 11px;
  max-width: 70px;
  width: 70px;
  height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.rm-icon{
  color: red;
  cursor: pointer;
}

.ico-btn{
  height: 32px;
  margin: 0.5rem 0.25rem;
}
</style>