<template>
<div class="configuration-component">
    <div class="page-content">
        <b-row>
            <b-col>

                <b-row class="my-2">
                    <b-col>
                        <b-form-group label="Start Date*" 
                            description="Select the day the campaign should start"
                            >
                            <b-form-input placeholder="Start Date" 
                            type="date" 
                            v-model="adForm.start">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="End Date*"
                            description="Select the day the campaign should end"
                            >
                            <b-form-input placeholder="End Date" 
                            type="date" 
                            v-model="adForm.end"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            
                <b-row>
                    <b-col>
                        <b-form-group label="Pixel Tracker URL"
                        description="After crossing End Date, the value is reset to blank"
                         :state="state" :invalid-feedback="invalidFeedback">
                        <b-form-input v-model="adForm.pixel_tracker_url" trim></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="pt-4">
                        <b-form-group
                        description="Select if you want to disable the lottie in the app">
                            <b-form-checkbox
                                v-model="adForm.disabled"
                                >
                                <b>Disabled</b>
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-form-file
                v-model="adFile"
                accept="application/json"
                class="my-2"
                no-drop
                @change="uploadLottie"
                ></b-form-file>

                <b-button variant="primary" class="my-2" @click="saveAd">Update</b-button>

            </b-col>
            <b-col>
                <div v-if="adForm && adForm.lottieFile">
                    <lottie-vue-player :src="adForm.lottieFile"
                                    :autoplay="true"
                                    :loop="true"
                                    style="width: 100%; height:500px">
                    </lottie-vue-player>

                    <p v-if="endDate" class="py-2">Campaign ends on {{ endDate }}</p>
                </div>
                <div v-else> Uploaded Animation will show here </div>
            </b-col>
        </b-row>
        
    </div>
</div>
</template>


<script>
import axios from 'axios'
import moment from 'moment'
import { nextTick } from 'vue'

export default {
name: 'IntroAnimation',
data () {
    return {
        adFile:null,
        // unmutable date just to view the end date for current campaign
        endDate:null,
        adForm:{
            lottieFile: null,
            pixel_tracker_url:'',
            disabled:false,
            start:'',
            end:''
        },
        options: {
            minimizable: false,
            playerSize: "standard",
            backgroundColor: '#fff',
            backgroundStyle: 'color',
    }
    }
},
mounted(){
    this.getAds()
},
computed:{
    state() {
        return this.adForm.pixel_tracker_url.includes('https://')
    },
    invalidFeedback() {
        return 'URL must start with https://'
    }
},
methods: {
    async getAds(){
        const url = `${this.$http.url}/ads`
        const response = await axios.get(url)
        const ad = response.data.data[0]
        if(ad){
            this.formatResponseToView(ad) 
        }
    },
    formatResponseToView(ad){
        this.adForm._id = ad._id
        this.adForm.lottieFile = ad.lottieFile
        this.adForm.start = ad.start.substring(0,10)
        this.adForm.end = ad.end.substring(0,10)
        this.adForm.pixel_tracker_url = ad.pixel_tracker_url
        this.adForm.disabled = ad.disabled
        this.endDate = moment(ad.end).utc().format('MMMM Do YYYY, h:mm:ss a');
    },
    saveAd(){
        if(this.validateFormFilled()){
            if(this.adForm._id){
                this.updateAd()
            }else{
                this.createAd()
            }
        }
    },
    validateFormFilled(){
        if((this.adForm.start ==='') || (this.adForm.end ==='') || (this.adForm.lottieFile===null)){
            this.$notify({
            group: 'notifications',
            title: 'All fields are required',
            text: '',
            type: 'error'
            })
            return false
        }
        return true
    },
    async createAd(){
        const url = `${this.$http.url}/ads`
        const response = await axios.post(url,this.adForm)
        if(response.data.success){
            this.adForm._id = response.data.data.insertedId
            this.$notify({
            group: 'notifications',
            title: 'Ad has been created',
            text: '',
            type: 'success'
          })
        }else{
            this.$notify({
            group: 'notifications',
            title: 'Failed to create ad',
            text: '',
            type: 'error'
          })   
        }
    },
    async updateAd(){
        const url = `${this.$http.url}/ads/${this.adForm._id}`
        const response = await axios.put(url,this.adForm)
        if(response.data.success){
            this.$notify({
            group: 'notifications',
            title: 'Ad has been updated',
            text: '',
            type: 'success'
          })
        }else{
            this.$notify({
            group: 'notifications',
            title: 'Failed to update ad',
            text: '',
            type: 'error'
          })   
        }
    },
    uploadLottie(event){
      this.$set(this.adForm,'lottieFile',null)
      let input = event.target
      this.formData = new FormData()
      if (input.files && input.files[0]) {

        this.formData.append('file', input.files[0])
        this.$http.post(`/ads/upload`,
          this.formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then((res) => {
          if(res.success){
            const filePath = res.data.path
            nextTick(()=>{
                this.$set(this.adForm,'lottieFile',filePath)    
            })
          }
        }).catch((err) => {
          console.log(err)
          this.formData = new FormData()
        })

      }
    },
}
}
</script>  