<template>
  <span v-if="status">
    <b-badge :variant="getVariant(status)"  class="p-1">
      <span>{{ status }}</span>
    </b-badge>
  </span>
</template>
<script>

export default {
  name: 'EpisodeStatus',
  props: {
    status: {
      type: String,
      required: true
    }
  },
  methods: {
    getVariant(code) {
      const variants = {
        transcoding: 'warning',
        importing: 'warning',
        audio_imported: 'warning',
        scheduled: 'info',
        transcoding_error: 'danger',
        published: 'success',
        draft: 'secondary'
      }

      return variants[code] || ''
    }
  }
}
</script>
<style scoped lang="scss">
 .badge {
   span {
     text-transform: capitalize;
   }
 }
</style>
