<template>
  <b-col>
    <div class="video-labels">
      {{imageSize.title}}<br/>
      <strong class="required" v-if="imageSize.required">* (Required to publish)</strong>
    </div>
    <b-form-text>
      {{imageSize.description}}
    </b-form-text>
    <b-form-file
      class="inputImages"
      :id="'image-'+imageSize.imageKey"
      v-model="imageSize.store"
      @change="uploadImage(imageSize, $event)"
      :state="imageSize.valid"
      :placeholder="imageSize.placeholder" />
    <div class="images-wrapper" v-if="content.images">
      <article v-for="(value, key) in content.images[imageSize.imageKey]" :key="key">
        <image-with-resolution :src="value" :alt="key" />
      </article>
    </div>
    <LoaderInline v-if="imageSize.loading"/>
  </b-col>
</template>

<script>
import LoaderInline from "../../share/LoaderInline"
import ImageWithResolution from "../../share/ImageWithResolution"
export default {
  name: "VideosImagesUploader",
  components: {
    ImageWithResolution,
    LoaderInline
  },
  props: {
    imageSize: {
      type: Object
    },
    content: {
      type: Object
    },
    apiURL: {
      type: String
    }
  },
  methods: {
    isFileSizeValid (file) {
      return file.size <= 1000000
    },
    uploadImage (imageSizeDefinition, event) {
      setTimeout(() => {
        const file = event.target.files[0]
        if (!file) {
          return
        }
        const isValidFileType = /\.(jpe?g|png|gif)$/i.test(file.name)

        if (!isValidFileType) {
          this.$notify({
            group: 'notifications',
            title: 'Image should be jpg or png format :(',
            text: '',
            type: 'error'
          })
          imageSizeDefinition.valid = false
          return
        }

        if(!this.isFileSizeValid(file)){
          this.$notify({
            group: 'notifications',
            title: 'Image size must be less than 1MB :(',
            text: '',
            type: 'error'
          })
          return
        }

        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = (e) => {
          const URL = window.URL || window.webkitURL
          const image = new Image()
          image.src = URL.createObjectURL(file)
          image.onload = () => {
            const width = image.width
            const height = image.height
            const iWidth = imageSizeDefinition.width
            const iHeight = imageSizeDefinition.height
            const isInvalidWidthHeight = width !== iWidth || height !== iHeight

            if (isInvalidWidthHeight) {
              this.$notify({
                group: 'notifications',
                title: `Image size allowed ${imageSizeDefinition.width}x${imageSizeDefinition.height}px, you uploaded one with the following size: ${width}x${height}px :(`,
                text: '',
                type: 'error'
              })

              imageSizeDefinition.valid = false
              return
            }

            this.formData = new FormData()
            this.formData.append('file', file)
            imageSizeDefinition.loading = true

            const imageResizeAPIURL = this.apiURL + `?size=${imageSizeDefinition.imageKey}`
            const $config = {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }

            this.$http.post(imageResizeAPIURL, this.formData, $config).then((res) => {
              let response = res.data
              const content = this.content
              response.images.forEach(img => {
                if (!content.images[response.type]) {
                  content.images[response.type] = {}
                }
                content.images[response.type][img.label] = img.url
              })
              imageSizeDefinition.valid = true
            }).catch((err) => {
              console.log(err)
              imageSizeDefinition.valid = false
              this.$notify({
                group: 'notifications',
                title: `There was an error while uploading this file`,
                text: '',
                type: 'error'
              })
            }).finally(() => {
              imageSizeDefinition.loading = false
            })
          }
        }
      }, 1000)
    },
  }
}
</script>

<style scoped lang="scss">
.inputImages {
  margin: 5px 0 10px;
}

.images-wrapper {
  margin-bottom: 15px;
  article {
    max-width: 150px;
    display: inline-block;
    margin-right: 3px;
    @media (max-width: 768px) {
      margin-bottom: 5px;
      max-width: 100%;
    }
  }
}
</style>
