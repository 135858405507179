<template>
  <div class="podcast-item-wrapper" v-if="podcast">
    <div class="item-header">
      <div class="row align-items-center">
        <div class="col-12 col-md-6">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 pl-0">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'podcast-all' }">Podcasts</router-link>
              </li>
              <li class="breadcrumb-item active">{{ pageTitle }}</li>
            </ol>
          </nav>
        </div>
        <div class="col-12 col-md-6 text-right actions">
          <router-link :to="{ name: 'podcast-episodes', params: { id: podcast._id}}" class="btn">
            <i class="fas fa-file-audio"></i>
            Episodes
          </router-link>
          <button class="btn" v-b-modal.modalImagesPodcast>
            <i class="fas fa-images"></i>
            Images
          </button>
          <button class="btn" v-b-modal.modalNotifications>
            <i class="fas fa-paper-plane"></i>
            Send Notification
          </button>
          <button class="btn page-action" @click="save">
            <i class="fas fa-check"></i>
            Update
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-8 offset-2">
        <div class="mt-3">
          <podcast-form :podcast="podcast" :is-edit="true"></podcast-form>
        </div>
      </div>
    </div>

    <b-modal id="modalImagesPodcast" ref="modalImagesPodcast" :ok-only="true" ok-title="close" size="lg" title="Images">
      <div class="row modal-wrapper">
        <div class="col-6" v-for="platform in platforms" v-bind:key="platform.platform">
          <upload @imgUploaded="imgUploaded" :data="platform"></upload>
        </div>
      </div>
    </b-modal>


    <b-modal id="modalNotifications" ref="modalNotifications" :ok-only="true" ok-title="Send"
             @ok="sendNotification" size="lg" title="Send Notification">
      <div class="modal-wrapper podcast-form" v-if="notification">
        <div class="row">
          <b-col>
            <b-link target="_blank" href="https://www.emojicopy.com/">Copy-paste emojis from here</b-link>
          </b-col>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <b-form-group>
              <label class="has-form-text">English Title<span>*</span></label>
              <b-form-input placeholder="New Episodes Out Now!!!" v-model="notification.title.en"></b-form-input>
              <span class="form-text text-muted">Title On the notification.</span>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group>
              <label class="has-form-text">Spanish Title<span>*</span></label>
              <b-form-input placeholder="¡¡¡Nuevos episodios ya disponibles!!!" v-model="notification.title.es"></b-form-input>
              <span class="form-text text-muted">Title On the notification.</span>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <b-form-group>
              <label class="has-form-text">English Message<span>*</span></label>
              <b-form-textarea rows="3" v-model="notification.description.en"></b-form-textarea>
              <span class="form-text text-muted">Message on the notification.</span>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group>
              <label class="has-form-text">Spanish Message<span>*</span></label>
              <b-form-textarea rows="3" v-model="notification.description.es"></b-form-textarea>
              <span class="form-text text-muted">Message on the notification.</span>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>
<script>
  import PodcastForm from "@/components/admin/podcasts/PodcastForm.vue";
  import axios from "axios";
  import upload from '../../share/UploadImage.vue'

  export default {
    name: 'PodcastEdit',
    components: { PodcastForm, upload},
    data: () => {
      return {
        podcast: null,
        pageTitle: null,
        platforms: {
          android: {
            label: 'Android - 1296x560px',
            description: 'This will be used on the podcasts section of LaMusica app/web',
            platform: 'android',
            isLoading: false,
            width: 1296,
            height: 560,
            url: ''
          },
          ios: {
            label: 'iOS - 1092x432px',
            description: 'This will be used on the podcasts section of LaMusica app/web',
            platform: 'ios',
            isLoading: false,
            width: 1092,
            height: 432,
            url: ''
          },
          exploreSquare: {
            label: 'Detail - 1440x1440px',
            platform: 'exploreSquare',
            description: 'This will be used on the detail view (app/web) and homepage card rows (app)',
            isLoading: false,
            width: 1440,
            height: 1440,
            url: ''
          },
          card: {
            label: 'Card - 1000x1100px',
            platform: 'card',
            description: 'This will be used on the featured card row (app)',
            isLoading: false,
            width: 1000,
            height: 1100,
            url: ''
          },
          landing: {
            label: 'Featured Header - 1920x850',
            platform: 'landing',
            description: 'This will be used on the tv and homepage website rotators',
            isLoading: false,
            width: 1920,
            height: 850,
            url: ''
          }
        },
        placeholderImage: {
          label: 'Placeholder - 800x800px',
          description: 'This will overwrite all episodes images across LaMusica App.',
          isLoading: false,
          width: 800,
          height: 800,
          url: ''
        },
        notification: {
          title: {
            en: '',
            es: ''
          },
          description: {
            en: '',
            es: ''
          },
          lastNotified: ''
        }
      }
    },
    async mounted () {
      await this.retrievePodcast()
    },
    methods: {
      async retrievePodcast () {
        const response = await this.$http.get(`/podcasts/${this.$route.params.id}`)
        this.podcast = response.data
        this.pageTitle = this.podcast.meta.titleEnglish
        const meta = this.podcast.meta

        this.platforms.android.url = (meta.exploreImagery && meta.exploreImagery.xxxhdpiImageURL) ? meta.exploreImagery.xxxhdpiImageURL : ''
        this.platforms.ios.url = (meta.exploreImagery && meta.exploreImagery['3xImageURL']) ? meta.exploreImagery['3xImageURL'] : ''
        this.platforms.exploreSquare.url = (meta.detailViewImagery && meta.detailViewImagery.xxxhdpiImageURL) ? meta.detailViewImagery.xxxhdpiImageURL : ''
        this.platforms.card.url = (meta.cardImagery && meta.cardImagery['3xImageURL']) ? meta.cardImagery['3xImageURL'] : ''
        this.platforms.landing.url = (meta.landing && meta.landing['3xImageURL']) ? meta.landing['3xImageURL'] : ''
        this.placeholderImage.url = meta.placeholderImage

        if (!this.podcast.meta.wideOrbitOnDemand) {
          this.podcast.meta.wideOrbitOnDemand = {}
        }

        if (!this.podcast.meta.web_ads) {
          this.podcast.meta.web_ads = {
            '320x100': [{
              tag: '',
              disable: false,
              start: null,
              end: null
            }],
            '300x250': [{
              tag: '',
              disable: false,
              start: null,
              end: null
            }],
            '728x90': [{
              tag: '',
              disable: false,
              start: null,
              end: null
            }],
            '728x180': [{
              tag: '',
              disable: false,
              start: null,
              end: null
            }],
          }

          this.podcast.meta.web_ads.disable_interstitial = false
        } else if(!this.podcast.meta.web_ads.disable_interstitial) {
          this.podcast.meta.web_ads.disable_interstitial = false
        }


        if (!this.podcast.meta.web_ads['320x100']) {
          this.podcast.meta['web_ads']['320x100'] = [{
            tag: '',
            disable: false,
            start: null,
            end: null
          }]
        }
        if (!this.podcast.meta.web_ads['728x180']) {
          this.podcast.meta['web_ads']['728x180'] = [{
            tag: '',
            disable: false,
            start: null,
            end: null
          }]
        }

        if (!this.podcast.meta.web_ads['468x60']) {
          this.podcast.meta['web_ads']['468x60'] = [{
            tag: '',
            disable: false,
            start: null,
            end: null
          }]
        }

        if (this.podcast.meta.notification) {
          this.notification = this.podcast.meta.notification
        }
      },
      save () {
        if (this.podcast.meta.titleEnglish === '') {
          this.$notify({
            group: 'notifications',
            title: 'Missing podcast English Title :(',
            text: '',
            type: 'error'
          })
          return
        }

        if (this.podcast.meta.titleSpanish === '') {
          this.$notify({
            group: 'notifications',
            title: 'Missing podcast Spanish Title :(',
            text: '',
            type: 'error'
          })
          return
        }

        if (this.podcast.meta.descriptionEnglish === '') {
          this.$notify({
            group: 'notifications',
            title: 'Missing podcast English Description :(',
            text: '',
            type: 'error'
          })
          return
        }

        if (this.podcast.meta.descriptionSpanish === '') {
          this.$notify({
            group: 'notifications',
            title: 'Missing podcast Spanish Description :(',
            text: '',
            type: 'error'
          })
          return
        }

        if (this.podcast.meta.slug === '') {
          this.$notify({
            group: 'notifications',
            title: 'Missing podcast slug :(',
            text: '',
            type: 'error',
            duration: -1
          })
          return
        }

        this.podcast.meta.notification = this.notification

        EventHandler.emit('isLoading', true)

        this.savePodcast()
            .then(async (res) => {
              if (!res.success) {
                EventHandler.emit('isLoading', false)
                return this.$notify({
                  group: 'notifications',
                  title: 'Error',
                  text: res.message,
                  type: 'error',
                  duration: -1
                })
              }

              if (!this.podcast.published && ((!this.podcast.meta.slug || this.podcast.meta.slug === ''))) {
                this.$notify({
                  group: 'notifications',
                  title: 'Podcast updated but NOT published. Missing Audio file or Slug meta',
                  text: '',
                  type: 'warning',
                  duration: -1
                })
              } else {
                this.$notify({
                  group: 'notifications',
                  title: 'Podcast updated successfully :)',
                  text: '',
                  type: 'success'
                })
              }
            })
            .catch((err) => {
              console.log(err)

              this.$notify({
                group: 'notifications',
                title: 'Something went wrong when we tried to save the podcast.  Please reopen this page and try again.',
                text: '',
                type: 'error',
                duration: -1
              })
            })
            .finally(() => {
              EventHandler.emit('isLoading', false)
            })
      },

      savePodcast () {
        if (!this.podcast.meta.slug || this.podcast.meta.slug === '') {
          this.podcast.published = false
        }

        let podcast = JSON.parse(JSON.stringify(this.podcast))

        return this.$http.put('/podcasts', { podcast, isUpdate: true })
      },

      sendNotification ($event) {
        if (this.validateNotifications()) {
          const notify = confirm('Are you sure you want to send notification to all subscribers?')
          if (notify) {
            this.sendUpdateNotification()
          }
        } else {
          $event.preventDefault()
        }
      },

      validateNotifications () {
        if (!this.notification.title.en || this.notification.title.en === ''
            && !this.notification.description.en || this.notification.description.en === '') {
          return this.$notify({
            group: 'notifications',
            title: 'Error',
            text: 'All notification fields are required to send a notification',
            type: 'error'
          })
        }
        return true
      },

      async sendUpdateNotification () {
        const onesignalSegment = `(Mixpanel) podcast_${this.podcastId}`
        const url = `${this.$http.url}/notifications/segment?oss=${onesignalSegment}`
        const podcastSlug = this.podcast.meta.slug
        const inputBody = {
          slug: podcastSlug,
          ...this.notification,
        }

        const response = await axios.post(url, inputBody)

        if (response.data.success) {
          this.$notify({
            group: 'notifications',
            title: response.data.message,
            text: '',
            type: 'success'
          })
        } else {
          this.$notify({
            group: 'notifications',
            title: response.data.message,
            text: '',
            type: 'error'
          })
        }
      },

      imgUploaded (formData, data) {
        this.image = formData
        this.platforms[data.platform].isLoading = true
        this.$http.post(`/common/image?resize=${data.platform}`,
            this.image,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        )
            .then((res) => {
              this.platforms[res.data.device]['url'] = res.data.images[0]
              switch (res.data.device) {
                case 'android':
                  this.podcast.meta.exploreImagery.xxxhdpiImageURL = res.data.images[0]
                  this.podcast.meta.exploreImagery.xxhdpiImageURL = res.data.images[1]
                  this.podcast.meta.exploreImagery.xhdpiImageURL = res.data.images[2]
                  this.podcast.meta.exploreImagery.hdpiImageURL = res.data.images[3]
                  this.podcast.meta.exploreImagery.mdpiImageURL = res.data.images[4]
                  break
                case 'ios':
                  this.podcast.meta.exploreImagery['3xImageURL'] = res.data.images[0]
                  this.podcast.meta.exploreImagery['2xImageURL'] = res.data.images[1]
                  break
                case 'exploreSquare':
                  this.podcast.meta.detailViewImagery.xxxhdpiImageURL = res.data.images[0]
                  this.podcast.meta.detailViewImagery.xxhdpiImageURL = res.data.images[1]
                  this.podcast.meta.detailViewImagery.xhdpiImageURL = res.data.images[2]
                  this.podcast.meta.detailViewImagery.hdpiImageURL = res.data.images[3]
                  this.podcast.meta.detailViewImagery.mdpiImageURL = res.data.images[4]
                  this.podcast.meta.detailViewImagery['3xImageURL'] = res.data.images[5]
                  this.podcast.meta.detailViewImagery['2xImageURL'] = res.data.images[6]
                  break
                case 'card':
                  this.podcast.meta.cardImagery['3xImageURL'] = res.data.images[0]
                  this.podcast.meta.cardImagery['2xImageURL'] = res.data.images[1]
                  break
                case 'landing':
                  this.podcast.meta.landing = {}
                  this.podcast.meta.landing['3xImageURL'] = res.data.images[0]
                  this.podcast.meta.landing['2xImageURL'] = res.data.images[1]
                  this.podcast.meta.landing['1xImageURL'] = res.data.images[2]
                  break
              }

              this.platforms[res.data.device].isLoading = false
              // data.isLoading = false
            })
            .catch((e) => {
              console.log('FAILURE!!', e)
            })
      },
    }
  }
</script>
