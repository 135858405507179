<template>
      <div class="shows-component">
      <div class="page-header">
        <div class="row align-items-center header">
          <div class="col-9">
            <div class="title">
              <router-link to="/admin/shows">Shows </router-link>/
              <span> {{ showMeta.titleEnglish }} </span>
            </div>
          </div>
          <div class="col d-flex justify-content-end">
            <div class=" btn btn-danger" @click="save()">Update</div>
          </div>

        </div>
      </div>


      <div class="page-content">


        <div class="row">
        <div class="col-md-12">
          <div class="controls-wrapper">
            <ul>
              <b-btn @click="addShowEpisode" class="modal-btn">Add Episodes</b-btn>
              <b-btn v-b-modal="'metadataModal'" class="modal-btn">Metadata</b-btn>
              <b-btn v-b-modal="'imagesModal'" class="modal-btn">Images</b-btn>
              <b-btn v-if="show" class="modal-btn" @click.self="show.published = !show.published">
                <b-form-checkbox v-model="show.published" @click.self="show.published = !show.published" class="publish-check" name="check-button" switch>
                  Publish
                </b-form-checkbox>
              </b-btn>
            </ul>
          </div>
        </div>
      </div>

      <div v-if="this.show" class="row">

        <div class="col-sm-8">
          <div class="d-flex justify-content-between">
            <div>
              Total {{totalShowEpisodeCount}} episodes
            </div>
            <div class="px-1 draft">
              {{ this.show.episodes_stats.draft }} Draft Episodes
            </div>
            <div class="px-1 scheduled">
              {{ this.show.episodes_stats.scheduled }} Scheduled Episodes
            </div>
            <div class="px-1 published">
              {{ this.show.episodes_stats.published }} Published Episodes
            </div>
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-12">
          <div v-if="showEpisodes.length>0">
              <div v-for="(eachEpisode, idx) in showEpisodes" :key="idx" class="d-flex my-2 p-2 align-items-center show-videos">
                <div class="mx-2">{{ idx+1 }}</div>
                <div class="mx-2">
                  <b-img :src="getThumbnail(eachEpisode.images.landscape)" class="show-list-img"></b-img>
                </div>
                <div class="d-flex flex-column flex-grow-1">
                  <div class="mx-2">{{ eachEpisode.title.en }}</div>
                  <div class="mx-2">{{ formatDate(eachEpisode.published_at) }}</div>
                  <div class="mx-2"> <episode-status :status="eachEpisode.status"></episode-status></div>
                </div>

                <div class="mx-2">
                  <b-button @click="openEpisodeEdit(eachEpisode)" variant="danger" v-b-tooltip.hover title="Edit episode">
                    <b-icon-box-arrow-up-right ></b-icon-box-arrow-up-right>
                  </b-button>
                </div>
                <div class="mx-2">
                  <b-button variant="danger" v-b-tooltip.hover title="Remove" @click="removeFromShow(eachEpisode)">
                    X
                  </b-button>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-grow-1" >
                  <b-btn size="sm" variant="danger" v-if="pagination.hasMore" @click="loadMoreVideos()">Load More...</b-btn>
                </div>
                <b-btn class="mx-3" variant="danger" size="sm" @click="scrollToTop()"> &#x2191; </b-btn>
              </div>

          </div>
          <div v-else class="col d-flex justify-content-center">
            No episodes added to the show
          </div>
        </div>
      </div>

      </div>

      <b-modal id="metadataModal" size="xl" title="Show Metadata">
        <MetadataForm :meta="showMeta" />
      </b-modal>
      <b-modal id="imagesModal" size="xl" title="Show Images">
        <div class="row modal-wrapper">
          <div class="col-6" v-for="platform in platforms" v-bind:key="platform.platform">
            <upload @imgUploaded="imgUploaded" :data="platform"></upload>
          </div>
        </div>
      </b-modal>

      </div>
</template>

<script>
import MetadataForm from '@/components/admin/shows/MetadataForm.vue'
import SceneSearch from '@/components/admin/shows/episodes/SceneSearch.vue'
import upload from '@/components/share/UploadImage'
import draggable from 'vuedraggable'
import moment from 'moment'
import ShowValidation from './Validation'
import EpisodeStatus from '../podcasts/episode-status.vue'

export default {
  name:'ShowsEdit',
  components:{
    draggable,
    MetadataForm,
    upload,
    SceneSearch,
    EpisodeStatus
  },
  data(){
    return {
      platforms: {
          android: {
            label: 'Android - 1296x560px',
            description: 'This will be used on the playlists section of LaMusica app/web',
            platform: 'android',
            width: 1296,
            height: 560,
            url: '',
            uploadURL: `/common/image?resize=android`
          },
          ios: {
            label: 'iOS - 1092x432px',
            description: 'This will be used on the playlists section of LaMusica app/web',
            platform: 'ios',
            width: 1092,
            height: 432,
            url: '',
            uploadURL: `/common/image?resize=ios`
          },
          exploreSquare: {
            label: 'Detail - 1440x1440px',
            platform: 'exploreSquare',
            description: 'This will be used on the detail view (app/web) and homepage card rows (app)',
            width: 1440,
            height: 1440,
            url: '',
            uploadURL: `/common/image?resize=exploreSquare`
          },
          card: {
            label: 'Card - 1000x1100px',
            platform: 'card',
            description: 'This will be used on the featured card row (app)',
            width: 1000,
            height: 1100,
            url: '',
            uploadURL: `/common/image?resize=card`
          },
          landing: {
            label: 'Featured Header - 1920x850',
            platform: 'landing',
            description: 'This will be used on the tv and homepage website rotators',
            isLoading: false,
            width: 1920,
            height: 850,
            url: '',
            uploadURL: `/common/image?resize=landing`
          },
          portrait: {
            label: 'Portrait - 1080x1920px',
            platform: 'portrait',
            description: 'Portrait tiles are new way to showcase content in the platform',
            isLoading: false,
            width: 1080,
            height: 1920,
            url: '',
            uploadURL: `/common/image?resize=portrait`
          }
        },
      showMeta: {
        titleEnglish:'',
        titleSpanish:'',
        descriptionEnglish:'',
        descriptionSpanish:'',
        slug:'',
        isSponsored:false,
        videoCount: 0,
        disableAds: false,
        type: 'show',
        exploreImagery: {
            '2xImageURL': '',
            '3xImageURL': '',
            mdpiImageURL: '',
            hdpiImageURL: '',
            xhdpiImageURL: '',
            xxhdpiImageURL: '',
            xxxhdpiImageURL: ''
          },
        detailViewImagery: {
          '2xImageURL': '',
          '3xImageURL': '',
          mdpiImageURL: '',
          hdpiImageURL: '',
          xhdpiImageURL: '',
          xxhdpiImageURL: '',
          xxxhdpiImageURL: ''
        },
        cardImagery: {
          '2xImageURL': '',
          '3xImageURL': ''
        },
        landing: {
          '2xImageURL': '',
          '3xImageURL': ''
        },
        portrait: {
          '1xImageURL': '',
          '2xImageURL': '',
          '3xImageURL': ''
        },
        video_ads_settings: {
            isSponsored: true,
            sponsored: {
              start: null,
              end: null
            },
            disableAds: false,
            DFPPrerollVideoAdUnitId: null,
            WebVideoAdUnitId: null,
            AmazonTVVideoAdUnitId: null,
            AndroidTVVideoAdUnitId: null,
            RokuTVVideoAdUnitId: null,
            AppleTVVideoAdUnitId: null
        },
        disable_interstitial: false,
        episode_stats:{
          draft:-1,
          unpublished:-1,
          scheduled:-1,
          total:-1,
        }
      },
      show:null,
      showEpisodes:[],
      pagination:{
        timestamp:null,
        hasMore:false,
        showVideosPerPage: 30,
      },
    }
  },
  computed:{
    totalShowEpisodeCount(){
      return this.show.episodes_stats.draft + this.show.episodes_stats.scheduled + this.show.episodes_stats.published
    }
  },
  methods:{
    publishBtnTitle(ep){
      return ep.status==='published'? 'Unpublish' : 'Publish'
    },
    publishBtnVariant(ep){
      return ep.status==='published'? 'danger' : 'success'
    },
    async addShowEpisode(){
      const newEpisode =  {
          title: {
              en: '',
              es: ''
          },
          description:{
              en: '',
              es: ''
          },
          video:{},
          images:{
              landscape:'',
              portrait:'',
              square:''
          },
          published:false,
          duration: -1,
          type:'show-episode',
          show_id:this.show._id,
          disable_ads:false,
          created_at:new Date(),
          published_at:new Date(),
          status:'draft'
        }
        const showId = this.$route.params.id
        const showEpisodeRes = await this.$http.post('/show-episodes',  {episode: newEpisode},)
        if(showEpisodeRes.success){
          const episodeId = showEpisodeRes.data.insertedId
          this.$router.push(`/admin/shows/edit/${showId}/episodes/edit/${episodeId}`)
        }
    },
    openEpisodeEdit(episode){
       this.$router.push(`/admin/shows/edit/${episode.show_id}/episodes/edit/${episode._id}`)
    },
    async checkBeforeUnpublish(episode){
      const action = !episode.published
      if(!action){
        const isRemoved = await this.$http.put(`/video-section/remove-resource/show-episode/${episode._id}`)
        if(!isRemoved.success){
              this.$notify({
                group: 'notifications',
                title: 'Cannot unpublish show',
                text: 'Failed to remove show from video section',
                type: 'error'
              })
              return false
        }
      }
      this.togglePublish(episode)
    },
    async togglePublish(episode){
      const url = `/show-episodes/${episode._id}/publish`
      const action = !episode.published

      // if being published
      if(action){
        const metadataIsValid = ShowValidation.validationForPublishedShowEpisodes(episode)

        if(!metadataIsValid){
          this.$notify({
            group: 'notifications',
            title: 'Incomplete Metadata',
            text: 'All information is required for a published episode.',
            type: 'error'
          })
          return
        }
      }else{
        //validate doesn't exist in video section
        const isRemoved = await this.$http.put(`/video-section/remove-resource/show-episode/${episode._id}`)

        if(!isRemoved.success){
          this.$notify({
              group: 'notifications',
              title: 'Cannot unpublish episode',
              text: 'Failed to remove episode from video section',
              type: 'error'
          })
          return
        }
      }

      this.$http.put(url, {published: !episode.published}).then(() => {
        this.$http.get(`/shows/${episode.show_id}/scan-stats`)
        episode.published = !episode.published
        if(episode.published){
          this.show.episodes_stats.published++
          this.show.episodes_stats.draft--
        }else{
          this.show.episodes_stats.published--
          this.show.episodes_stats.draft++
        }
        this.$notify({
            group: 'notifications',
            title: 'Episode updated!',
            text: '',
            type: 'success'
          })
      })
    },
    formatDate(date){
      return moment(date).format('dddd, MMMM Do, h:mm a')
    },
    scrollToTop(){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    loadMoreVideos(){
      this.getShowEpisodes()
    },
    async getShowEpisodes(){
      const showId = this.$route.params.id
      let showEpisodeUrl= `/shows/${showId}/episodes?limit=${this.pagination.showVideosPerPage}`
      if(this.pagination.timestamp){
        showEpisodeUrl +=`&timestamp=${this.pagination.timestamp}`
      }
      const showSceneResponse = await this.$http.get(showEpisodeUrl)
      if(!showSceneResponse.success){
        // show error
        this.$notify({
            group: 'notifications',
            title: 'Failed to fetch episodes',
            text: '',
            type: 'error'
        })
        return
      }
      this.pagination.timestamp = showSceneResponse.data.timestamp
      this.pagination.hasMore = showSceneResponse.data.items.length >= this.pagination.showVideosPerPage

      const episodes = showSceneResponse.data.items
      const merged = this.showEpisodes.concat(episodes)
      this.showEpisodes = merged
    },
    getThumbnail(img){
      if(img && img !==''){
        return img
      }
      return '/static/img/LaMusica-Icon-playlist-placeholder.jpg'
    },
    isInputValid(){
      if(this.show.published){
        const metadataIsValid = ShowValidation.metadataValidationForPublishedShows(this.show.meta)
        const imageIsValid = ShowValidation.imageValidationForPublishedShows(this.show.meta)
        if(!metadataIsValid){
          this.$notify({
            group: 'notifications',
            title: 'Incomplete Metadata',
            text: 'Title, descriptions & slug is required for a published shows. Make sure all of those fields are filled.',
            type: 'error'
          })
          return false
        }

        if (!imageIsValid){
          this.$notify({
            group: 'notifications',
            title: 'Incomplete Images',
            text: 'All images are required for a published show',
            type: 'error'
          })
          return false
        }
      }
      return true
    },
    async save(){
      const showId = this.$route.params.id
      this.show.meta = this.showMeta
      if(this.isInputValid()){
        const res = await this.$http.put(`/shows/${showId}`, {show: this.show})

        if(res.success){
          this.$notify({
          group: 'notifications',
          title: 'Show updated successfully :)',
          text: '',
          type: 'success'
        })
        }else{
          this.$notify({
          group: 'notifications',
          title: 'Failed to update',
          text: '',
          type: 'error'
        })
        }
      }
    },
    async getShowDetails(){
      const showId = this.$route.params.id
      const showResponse = await this.$http.get(`/shows/${showId}`)
      this.show = showResponse.data
      this.showMeta = this.show.meta
      if(typeof this.showMeta.disable_interstitial === 'undefined') {
        this.showMeta.disable_interstitial = false
      }
      this.platforms.android.url = (this.showMeta.exploreImagery && this.showMeta.exploreImagery.xxxhdpiImageURL) ? this.showMeta.exploreImagery.xxxhdpiImageURL : ''
      this.platforms.ios.url = (this.showMeta.exploreImagery && this.showMeta.exploreImagery['3xImageURL']) ? this.showMeta.exploreImagery['3xImageURL'] : ''
      this.platforms.exploreSquare.url = (this.showMeta.detailViewImagery && this.showMeta.detailViewImagery.xxxhdpiImageURL) ? this.showMeta.detailViewImagery.xxxhdpiImageURL : ''
      this.platforms.card.url = (this.showMeta.cardImagery && this.showMeta.cardImagery['3xImageURL']) ? this.showMeta.cardImagery['3xImageURL'] : ''
      this.platforms.landing.url = (this.showMeta.landing && this.showMeta.landing['3xImageURL']) ? this.showMeta.landing['3xImageURL'] : ''
      this.platforms.portrait.url = (this.showMeta.portrait && this.showMeta.portrait['3xImageURL']) ? this.showMeta.portrait['3xImageURL'] : ''
    },
    async removeFromShow(episode){
      if(episode.published){
        this.$notify({
          group: 'notifications',
          title: 'Unpublish before deleting',
          text: 'You must unpublish the episode for deleting.',
          type: 'error'
        })
        return
      }
      const confirmDelete = confirm('Do you want to delete the episode?')
      const showId = this.$route.params.id
      if(confirmDelete){
        const showEpisodeRes = await this.$http.delete(`/show-episodes/${episode._id}`)
        if(showEpisodeRes.success){
          await this.$http.get(`/shows/${showId}/scan-stats`)
          await this.getShowDetails()

          const index = this.showEpisodes.indexOf(episode)
          this.showEpisodes.splice(index, 1)
        }else{
          this.$notify({
            group: 'notifications',
            title: 'Failed to delete',
            text: 'Backend server error',
            type: 'error'
          })
        }
      }

    },
    imgUploaded (data, res) {
        this.platforms[res.data.device]['url'] = res.data.images[0]
        const imageKeys = ['exploreImagery', 'detailViewImagery', 'cardImagery', 'landing', 'portrait']
        imageKeys.forEach((key) => {
          if (!this.showMeta[key]) {
            this.showMeta[key] = {}
          }
        })
        switch (res.data.device) {
          case 'android':
            this.showMeta.exploreImagery.xxxhdpiImageURL = res.data.images[0]
            this.showMeta.exploreImagery.xxhdpiImageURL = res.data.images[1]
            this.showMeta.exploreImagery.xhdpiImageURL = res.data.images[2]
            this.showMeta.exploreImagery.hdpiImageURL = res.data.images[3]
            this.showMeta.exploreImagery.mdpiImageURL = res.data.images[4]
            break
          case 'ios':
            this.showMeta.exploreImagery['3xImageURL'] = res.data.images[0]
            this.showMeta.exploreImagery['2xImageURL'] = res.data.images[1]
            break
          case 'exploreSquare':
            this.showMeta.detailViewImagery.xxxhdpiImageURL = res.data.images[0]
            this.showMeta.detailViewImagery.xxhdpiImageURL = res.data.images[1]
            this.showMeta.detailViewImagery.xhdpiImageURL = res.data.images[2]
            this.showMeta.detailViewImagery.hdpiImageURL = res.data.images[3]
            this.showMeta.detailViewImagery.mdpiImageURL = res.data.images[4]
            this.showMeta.detailViewImagery['3xImageURL'] = res.data.images[5]
            this.showMeta.detailViewImagery['2xImageURL'] = res.data.images[6]
            break
          case 'card':
            this.showMeta.cardImagery['3xImageURL'] = res.data.images[0]
            this.showMeta.cardImagery['2xImageURL'] = res.data.images[1]
            break
          case 'landing':
            this.showMeta.landing = {}
            this.showMeta.landing['3xImageURL'] = res.data.images[0]
            this.showMeta.landing['2xImageURL'] = res.data.images[1]
            this.showMeta.landing['1xImageURL'] = res.data.images[2]
            break
          case 'portrait':
            this.showMeta.portrait['3xImageURL'] = res.data.images[0]
            this.showMeta.portrait['2xImageURL'] = res.data.images[1]
            this.showMeta.portrait['1xImageURL'] = res.data.images[2]
            break
        }
        this.platforms[res.data.device].isLoading = false
      },
  },
  async mounted(){
    await this.getShowDetails()
    await this.getShowEpisodes()
  }
}
</script>

<style scoped>
.show-list-img{
  width: 100px;

}
.latest-window{
  margin-top: 10px;
  height: 600px;
  max-height: 600px;
  overflow: scroll;
}
.show-videos{
  background-color: white;
}

.draft{
  border-left: 2px solid #6c757d;
}
.scheduled{
  border-left: 2px solid #17a2b8;
}
.published{
  border-left: 2px solid green;
}
.b-form-checkbox[disabled] {
  opacity: 1; /* Set opacity to 1 to prevent greying out */
  background-color: #fff; /* Set background color to white */
}
</style>
