<template>
  <div class="advertisement-form">
    <section v-if="hasWebDisplayAds && item.web_ads">
      <h4>Web Display Ads</h4>
      <hr>
      <b-form-row>
        <b-col>
          <b-form-checkbox
              id="enabled_300x250"
              v-model="item.web_ads.disable_interstitial"
              name="enabled_300x250">
            Disable Interstitial
          </b-form-checkbox>
          <small>Chick this one to disable the Interstitial Ads for this resource.</small>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group label="Above Navigation Ad Unit ID">
            <b-form-input
              type="text"
              v-model="item.web_ads['728x90'][0].tag"
              placeholder="Ad Unit ID"
              trim>
            </b-form-input>
            <b-form-checkbox
              id="enabled_728x90"
              v-model="item.web_ads['728x90'][0].disable"
              name="enabled_728x90">
              Disable Ad
            </b-form-checkbox>
          </b-form-group>
          <small>
            An example would be <code>LaMusica_Web_ROS_728x90_320x50</code>. Sizes include: 970x90, 728x90, 320x50. If no value is provided we will not serve a display ad unit.
          </small>
        </b-col>
        <b-col>
          <b-form-group label="Beside Player Ad Unit ID">
            <b-form-input type="text"
                          v-model="item.web_ads['300x250'][0].tag"
                          placeholder="Ad Unit ID"
                          trim>
            </b-form-input>
            <b-form-checkbox
              id="enabled_300x250"
              v-model="item.web_ads['300x250'][0].disable"
              name="enabled_300x250">
              Disable Ad
            </b-form-checkbox>
            <small>
              An example would be <code>LaMusica_Web_ROS_300x250_300x600</code>. Sizes include: 300x250, 300x600. If no value is provided we will not serve a display ad unit.
            </small>
          </b-form-group>
        </b-col>
      </b-form-row>
      <br />
      <b-form-row v-if="item.web_ads['728x180'] && item.web_ads['320x100']">
        <b-col>
          <h6>Fixed Bottom Floating Ads</h6>
          <b-form-checkbox
            id="bottomFloatingAds"
            v-model="floatingAdsState"
            @change="updateCheckbox">
            Disable Ad
          </b-form-checkbox>
        </b-col>
      </b-form-row>

      <b-form-row v-if="item.web_ads['728x180'] && item.web_ads['320x100']">
        <b-col>
          <b-form-group label="For Desktop Size">
            <b-form-input id="webAd728x180"
                          type="text"
                          v-model="item.web_ads['728x180'][0].tag"
                          placeholder="Ad Unit ID">
            </b-form-input>
            <small>
              An example would be <code>LaMusica_Web_Fixed_Bottom_Floating_Desktop</code>. Supported Sizes: 728x180, 728x90.
            </small>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="For Mobile Size">
            <b-form-input id="webAd320x100"
                          type="text"
                          v-model="item.web_ads['320x100'][0].tag"
                          placeholder="Ad Unit ID">
            </b-form-input>

            <small>
              An example would be <code>LaMusica_Web_Fixed_Bottom_Floating_Mobile</code>. Supported Sizes: 320x100, 320x50.
            </small>
          </b-form-group>
        </b-col>
      </b-form-row>

      <br />

      <b-form-row>
        <b-col>
          <h6>
            <strong>Sticky Bottom Player Ad Unit</strong>
          </h6>
          <b-form-checkbox
            id="playerStickyAds"
            v-model="playerAdsState"
            @change="updateCheckbox">
            Disable Ad
          </b-form-checkbox>
        </b-col>
      </b-form-row>

      <br />

      <b-form-row v-if="item.web_ads['468x60'] && item.web_ads['468x60'][0]">
        <b-col>
          <b-form-group label="Player Ad Unit ID">
            <b-form-input id="webAd468x60"
                          type="text"
                          v-model="item.web_ads['468x60'][0].tag"
                          placeholder="Ad Unit ID">
            </b-form-input>
            <small>
              An example would be <code>LaMusica_Web_Player_468x60</code>. Supported Sizes: 468x60.
            </small>
          </b-form-group>
        </b-col>
      </b-form-row>

      <br />

    </section>
    <section v-if="hasWideOrbitCompanionAds">
      <h4>Wide Orbit Companion Ads Information</h4>
      <hr>
      <b-form-row>
        <b-col>
          <b-form-group label="Gateway Companion Banner Preroll">
            <b-form-checkbox v-model="item.hasCompanionAdPreroll" v-on:change="validateCheckbox(0)">Has Companion Ad Preroll</b-form-checkbox>
            <small>If this playlist should have a preroll gateway creative appear at the beginning of a playback session.</small>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Gateway Companion Banner Midroll">
            <b-form-checkbox v-model="item.hasCompanionAdMidroll">Has Companion Ad Midroll</b-form-checkbox>
            <small>If this playlist should have a midroll gateway creative appear after X songs played, where X is a remotely configured variable.</small>
          </b-form-group>
        </b-col>
      </b-form-row>
      <br />
      <b-form-row>
        <b-col>
          <b-form-group label="Wide Orbit Companion Ad Station Name">
            <span class="form-text text-muted">This is the station Name on Wide Orbit that the app will use to fetch pre-roll/mid-roll advertisements.</span>
            <input type="text" placeholder="Curated-61" v-model="item.companionAdStationId"/>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Wide Orbit Companion Ad Station ID">
            <span class="form-text text-muted">This is the station ID on Wide Orbit that the app will use to fetch pre-roll/mid-roll advertisements.</span>
            <input type="text" placeholder="3401" v-model="item.wideOrbitStationId"/>
          </b-form-group>
        </b-col>
      </b-form-row>
      <!-- <div class="col-6">
        <label>Wide Orbit Companion Ad Station Name</label>
        <span class="form-text text-muted">This is the station Name on Wide Orbit that the app will use to fetch pre-roll/mid-roll advertisements.</span>
        <input type="text" placeholder="Curated-61" v-model="playlist.meta.companionAdStationId"/>
      </div> -->
      <!-- <div class="col-6">
        <label>Wide Orbit Companion Ad Station ID</label>
        <span class="form-text text-muted">This is the station ID on Wide Orbit that the app will use to fetch pre-roll/mid-roll advertisements.</span>
        <input type="text" placeholder="3401" v-model="playlist.meta.wideOrbitStationId"/>
      </div> -->
      <br />
    </section>
    <h4>Video Pre-Roll Advertisement Configuration</h4>
    <hr>
    <b-form-row>
      <b-col>
        <b-form-group label="Sponsored Content">
          <b-form-checkbox
            id="isSponsored"
            v-model="item.isSponsored"
            v-on:change="validateCheckbox(1)"
            name="isSponsored">
            Content is sponsored
          </b-form-checkbox>
          <small>On LaMusica iOS, Android, & AppleTV, we keep a timer to prevent back-to-back item prerolls.  Checking is sponsored will make the preroll for this advertisement appear <strong>every time</strong>, regardless of the timer's state.</small>
        </b-form-group>

        <b-form-row v-if="item.isSponsored && item.sponsored">
          <b-col>
            <legend class="col-form-label">Start date</legend>
            <datetime type="datetime"
                      v-model="item.sponsored.start"
                      class="theme-orange"
                      :phrases="{ok: 'Continue', cancel: 'Exit'}"
                      use12-hour>
            </datetime>
          </b-col>

          <b-col>
            <legend class="col-form-label">End date</legend>
            <datetime type="datetime"
                      v-model="item.sponsored.end"
                      class="theme-orange"
                      :phrases="{ok: 'Continue', cancel: 'Exit'}"
                      use12-hour>
            </datetime>
          </b-col>
        </b-form-row>
      </b-col>
      <b-col>
        <b-form-group label="Disable Ads">
          <b-form-checkbox
            id="disableAds"
            v-model="item.disableAds"
            name="disableAds">
            Disable video pre-rolls on this content
          </b-form-checkbox>
          <small>Checking this box will disable all advertisements for this particular piece of content.</small>
        </b-form-group>
      </b-col>
    </b-form-row>
    <br />
    <b-form-row>
      <b-col>
        <b-form-group label="iOS & Android • DFP VAST Preroll Video Ad Unit">
          <b-form-input id="DFPPrerollVideoAdUnitId"
                        type="text"
                        v-model="item.DFPPrerollVideoAdUnitId"
                        placeholder=""
                        trim>
          </b-form-input>
          <small>The custom DFP ad unit that will be associated to this item on LaMusica's iOS & Android applications. An example value would be <code>/10297243/LaMusica_App_EditorialVideo_PreRoll_640x480v</code>.  If no value is provided, then we will use the default ad unit for this type of content.</small>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Web • DFP VAST Preroll Video Ad Unit">
          <b-form-input id="WebVideoAdUnitId"
                        type="text"
                        v-model="item.WebVideoAdUnitId"
                        placeholder=""
                        trim>
          </b-form-input>
          <small>The custom DFP ad preroll unit that will be associated to this item on LaMusica's website application for this content. An example value would be <code>/10297243/LaMusica_VideoPreRoll_ReggaetonVIP_640x480</code>.  If no value is provided, then we will use the default ad unit for this type of content.</small>
        </b-form-group>
      </b-col>
    </b-form-row>
    <br />
    <b-form-row>
      <b-col>
        <b-form-group label="FireTV • DFP VAST Preroll Video Ad Unit">
          <b-form-input id="AmazonTVVideoAdUnitId"
                        type="text"
                        v-model="item.AmazonTVVideoAdUnitId"
                        placeholder=""
                        trim>
          </b-form-input>
          <small>The custom DFP ad unit that will be associated to this item on LaMusica FireTV. An example value would be <code>/10297243/LaMusica_App_EditorialVideo_PreRoll_640x480v</code>.  If no value is provided, then we will use the default ad unit for this type of content.</small>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="AndroidTV • DFP VAST Preroll Video Ad Unit">
          <b-form-input id="AndroidTVVideoAdUnitId"
                        type="text"
                        v-model="item.AndroidTVVideoAdUnitId"
                        placeholder=""
                        trim>
          </b-form-input>
          <small>The custom DFP ad unit that will be associated to this item on LaMusica's AndroidTV application. An example value would be <code>/10297243/LaMusica_App_EditorialVideo_PreRoll_640x480v</code>.  If no value is provided, then we will use the default ad unit for this type of content.</small>
        </b-form-group>
      </b-col>
    </b-form-row>
    <br />
    <b-form-row>
      <b-col>
        <b-form-group label="Roku • DFP VAST Preroll Video Ad Unit">
          <b-form-input id="RokuTVVideoAdUnitId"
                        type="text"
                        v-model="item.RokuTVVideoAdUnitId"
                        placeholder=""
                        trim>
          </b-form-input>
          <small>The custom DFP ad unit that will be associated to this item on LaMusica's Roku application. An example value would be <code>/10297243/LaMusica_App_EditorialVideo_PreRoll_640x480v</code>.  If no value is provided, then we will use the default ad unit for this type of content.</small>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="AppleTV • DFP VAST Preroll Video Ad Unit">
          <b-form-input id="AppleTVVideoAdUnitId"
                        type="text"
                        v-model="item.AppleTVVideoAdUnitId"
                        placeholder=""
                        trim>
          </b-form-input>
          <small>The custom DFP ad unit that will be associated to this item on LaMusica's AppleTV application. An example value would be <code>/10297243/LaMusica_ConnectedTV_AppleTV_Video</code>.  If no value is provided, then we will use the default ad unit for this type of content.</small>
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>

export default {
  name: 'AdConfigurationForm',
  props: {
    item: {
      type: Object
    },
    hasWideOrbitCompanionAds: {
      type: Boolean
    },
    hasWebDisplayAds: {
      type: Boolean
    }
  },
  data(){
    return {
      floatingAdsState:false,
      playerAdsState: false,
    }
  },
  mounted(){
    if(this.item.web_ads && this.item.web_ads['728x180']){
      if(this.item.web_ads['728x180'][0].disable){
        this.floatingAdsState = this.item.web_ads['728x180'][0].disable // initializing checkbox
      }

      if(this.item.web_ads['468x60'] && this.item.web_ads['468x60'][0] && this.item.web_ads['468x60'][0].disable){
        this.playerAdsState = this.item.web_ads['468x60'][0].disable
      }
    }
  },
  methods:{
    updateCheckbox(){
      this.$nextTick( ()=>{
        this.item.web_ads['728x180'][0].disable = this.floatingAdsState
        this.item.web_ads['320x100'][0].disable = this.floatingAdsState

        if(this.item.web_ads['468x60'] && this.item.web_ads['468x60'][0]){
          this.item.web_ads['468x60'][0].disable = this.playerAdsState
        }
      })
    },
    validateCheckbox(event){
      var obj = {'btn':event}
      if(event===0){
        obj.value = !this.item.hasCompanionAdPreroll
      }else if(event===1){
        obj.value = !this.item.isSponsored
      }
      this.$emit('validateCheckBox',obj)
    },
  }
}
</script>

<style lang="scss">
.advertisement-form {
  .custom-checkbox {
    margin-bottom: 5px;
  }

  .form-group legend {
    font-size: 15px;
  }

  small {
    font-size: 13px;
  }
}
</style>
