import { render, staticRenderFns } from "./VideosImageUploader.vue?vue&type=template&id=812ac9c8&scoped=true"
import script from "./VideosImageUploader.vue?vue&type=script&lang=js"
export * from "./VideosImageUploader.vue?vue&type=script&lang=js"
import style0 from "./VideosImageUploader.vue?vue&type=style&index=0&id=812ac9c8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "812ac9c8",
  null
  
)

export default component.exports