<template>
  <div class="station-edit-component">
    <editors v-if="stationID" :resource="stationID" />

    <div class="page-header">
      <div class="row align-items-center">
        <div class="col-3">
          <div class="title">
            <router-link to="/admin/stations">Station </router-link>/
            <span v-if="station && station.title">{{station.title}}</span>
          </div>
        </div>
        <div class="col-9 pull-right">
          <div class="add-btn update-station" @click="showCancelConfirm">Cancel</div>
          <div class="add-btn update-station" @click="updateStation">Update</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="controls-wrapper">
            <ul>
              <b-btn v-b-modal.modalStationProgramming class="add-playlist modal-btn">Programming</b-btn>
              <b-btn v-b-modal.modalStationMeta class="modal-btn">Meta</b-btn>
              <b-btn v-b-modal.modalStationImages class="modal-btn">Add Images</b-btn>
              <b-btn v-b-modal.podcastsModal class="add-playlist modal-btn">Podcasts</b-btn>
              <b-btn v-b-modal.livestreamsModal class="add-playlist modal-btn">Livestreams</b-btn>
              <b-btn @click="openStationBucketModal" class="modal-btn">View Buckets</b-btn>
              <b-btn @click="openLightBoxModal" class="modal-btn">Lightbox</b-btn>
              <li @click="station.hideTitle = !station.hideTitle">{{station.hideTitle ? 'Show Title' : 'Hide Title'}}</li>
              <li @click="station.isFeatured = !station.isFeatured">{{station.isFeatured ? 'No Featured' : 'Is Featured'}}</li>
              <li @click="station.isSponsored = !station.isSponsored">{{station.isSponsored ? 'No Sponsored' : 'Is Sponsored'}}</li>
              <li @click="station.published = !station.published">{{ station.published ? 'Unpublish' : 'Publish' }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content">
      <div class="station-content-wrapper">
        <div class="row">

          <div class="col-8">
            <div class="station-area-outer">

              <draggable v-model="station.layout" class="location-area" :options="{group:{ name:'row', put: ['layout'], pull: ['row']}}">
                <div class="row-wrapper" v-for="(row, rowIndex) in station.layout" :key="rowIndex">
                  <div v-if="row['type'] !== 'ads'">
                    <div class="row-title">
                      {{row.title.en || 'Row English Title'}} <span>/ {{row.title.es || 'Row Spanish Title'}}</span>
                    </div>
                    <div class="row-type" v-if="rowIndex !== 0 && rowIndex !== 1">
                      <img src="/static/img/Drag_and_drop_icon.png" alt="" />
                    </div>
                    <div class="remove-row">
                      <div class="submenu-actions">
                        <div class="icon">
                          <i class="fas fa-ellipsis-h"></i>
                        </div>
                        <div class="actions">
                          <ul>
                            <li @click="editRow(row, rowIndex)">Edit</li>
                            <li @click="confirmRemoveRow(rowIndex)" v-if="rowIndex > 0">Remove</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <draggable v-model="row.items" class="card-area" :options="{group:{ name:'card', put:['newCard', 'card']}}" :class="{featured: rowIndex === 0, automated: row.automated && row.automated.indexOf('automated')> -1, carousel: row.type === 'carousel', landscape: row.image_type === 'landscape'}"
                               :move="checkCard" @start="showAlert = true" :index="rowIndex" v-if="Array.isArray(row.items)">

                      <div class="card-wrapper" v-for="(card, cardIndex) in row.items"
                           :key="cardIndex" :class="{featured: rowIndex === 0}">
                        <div class="card-wrapper-each" v-if="card">
                          <div class="card-img" :style="getCardStyle(card)">
                            <img :src="card.img[0]['750x750']" alt="" v-if="rowIndex !== 0"/>
                            <img :src="card.img[0]['1000x1100']" alt="" v-if="rowIndex === 0"/>
                          </div>
                          <div class="card-title" v-if="(card.titleSpanish || card.titleEnglish) && rowIndex !== 0 && row.displayTitles">
                            {{card.titleSpanish ? card.titleSpanish : card.titleEnglish}}
                          </div>
                          <div class="remove-row remove-row-card" @click="removeCard(rowIndex, cardIndex)">
                            <i class="fas fa-times"></i>
                          </div>
                        </div>
                      </div>

                      <!-- <div v-if="row.items.length === 0 && row.automated && row.automated.indexOf('automated')> -1">
                        <h5>{{automated_row_types[location.rows[rowIndex].automated].title}}</h5>
                        <p>{{automated_row_types[location.rows[rowIndex].automated].description}}</p>
                      </div> -->

                    </draggable>
                  </div>

                  <div class="ads-row" v-if="row['type'] === 'ads'">
                    <div class="row-title">
                      {{row.titleEnglish || 'Row English Title'}} <span>/ {{row.titleSpanish || 'Row Spanish Title'}}</span>
                    </div>
                    <div class="remove-row">
                      <div class="submenu-actions">
                        <div class="icon">
                          <i class="fas fa-ellipsis-h"></i>
                        </div>
                        <div class="actions">
                          <ul>
                            <li @click="editRow(row, rowIndex)">Edit</li>
                            <li @click="confirmRemoveRow(rowIndex)" v-if="rowIndex > 0">Remove</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="ads-form">
                      <b-form-row>
                        <b-col>
                          <strong class="ads-label">Background Image <strong class='required'>*</strong></strong>
                          <b-form-group>
                            <div class="ads-image-wrapper">
                              <img :src="row.bg_img" alt="" v-if="row.bg_img" class="ads-bg-img">
                            </div>
                            <small>Background creative (for iOS & Android)</small>
                            <b-form-file class="ads-img-input"
                                         v-model="row.bg_img"
                                         placeholder="876×1633px"
                                         @change.native="uploadAdsImage($event, 876, 1633, 'bg_img', rowIndex)"
                                         :state="Boolean(row.bg_img)"></b-form-file>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <strong class="ads-label">Overlay Image <strong class='required'>*</strong></strong>
                          <b-form-group>
                            <div class="ads-image-wrapper">
                              <img :src="row.overlay_img" alt="" v-if="row.overlay_img" class="ads-bg-img">
                            </div>
                            <small>Overlay creative (for iOS & Android)</small>
                            <b-form-file class="ads-img-input"
                                         v-model="row.overlay_img"
                                         @change.native="uploadAdsImage($event, 869, 1095, 'overlay_img', rowIndex)"
                                         :state="Boolean(row.overlay_img)"
                                         placeholder="869x1095px"></b-form-file>
                          </b-form-group>
                        </b-col>
                      </b-form-row>
                      <b-form-row>
                        <b-col>
                          <strong class="ads-label">Ipad Background Image <strong class='required'>*</strong></strong>
                          <b-form-group>
                            <div class="ads-image-wrapper">
                              <img :src="row.ipad_bg_img" alt="" v-if="row.ipad_bg_img" class="ads-bg-img">
                            </div>
                            <small>Background creative (for iPad/Tablets)</small>
                            <b-form-file class="ads-img-input"
                                         v-model="row.ipad_bg_img"
                                         @change.native="uploadAdsImage($event, 1536, 2048, 'ipad_bg_img', rowIndex)"
                                         :state="Boolean(row.ipad_bg_img)"
                                         placeholder="1536×2048px"></b-form-file>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <strong class="ads-label">Ipad Overlay Image <strong class='required'>*</strong></strong>
                          <b-form-group>
                            <div class="ads-image-wrapper">
                              <img :src="row.ipad_overlay_img" alt="" v-if="row.ipad_overlay_img" class="ads-bg-img">
                            </div>
                            <small>Overlay creative (for iPad/Tablets)</small>
                            <b-form-file class="ads-img-input"
                                         v-model="row.ipad_overlay_img"
                                         @change.native="uploadAdsImage($event, 1536, 1024, 'ipad_overlay_img', rowIndex)"
                                         :state="Boolean(row.ipad_overlay_img)"
                                         placeholder="1536x1024px"></b-form-file>
                          </b-form-group>
                        </b-col>
                      </b-form-row>

                      <b-form-row>
                        <b-col>
                          <strong class="ads-label">Web Image <strong class='required'>*</strong></strong>
                          <b-form-group>
                            <div class="ads-image-wrapper">
                              <img :src="row.web_image" alt="" v-if="row.web_image" class="ads-bg-img">
                            </div>
                            <small>Background image for web</small>
                            <b-form-file class="ads-img-input"
                                         v-model="row.web_image"
                                         @change.native="uploadAdsImage($event, 1920, 850, 'web_image', rowIndex)"
                                         :state="Boolean(row.web_image)"
                                         placeholder="1920x850px"></b-form-file>
                          </b-form-group>
                        </b-col>
                      </b-form-row>

                      <hr/>

                      <b-form-row>
                        <b-col>
                          <strong class="ads-label">URL <strong class='required'>*</strong></strong>
                          <b-form-group>
                            <b-form-input id="livestream_url"
                                          type="url"
                                          required
                                          v-model="row.url"
                                          placeholder="Ads url text...">
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-form-row>
                    </div>
                    <draggable v-model="row.items" class="card-area ads-section" :options="{group:{ name:'card', put:['newCard', 'card']}}"
                               :move="checkCard" @start="showAlert = true" :index="rowIndex" v-if="Array.isArray(row.items)">

                      <div class="card-wrapper" v-for="(card, cardIndex) in row.items"
                           :key="card._id" :class="{featured: rowIndex === 0}">
                        <div class="card-wrapper-each" v-if="card">
                          <div class="card-img" :style="getCardStyle(card)">
                            <img :src="card.img[0]['750x750']" alt="" v-if="rowIndex !== 0"/>
                            <img :src="card.img[0]['1000x1100']" alt="" v-if="rowIndex === 0"/>
                          </div>
                          <div class="card-title" v-if="(card.titleSpanish || card.titleEnglish) && rowIndex !== 0 && row.displayTitles">
                            {{card.titleSpanish ? card.titleSpanish : card.titleEnglish}}
                          </div>
                          <div class="remove-row remove-row-card" @click="removeCard(rowIndex, cardIndex)">
                            <i class="fas fa-times"></i>
                          </div>
                        </div>
                      </div>

                      <div class="message" v-if="row.items.length === 0">
                        <strong>Drag & Drop card in this area to deep link parallax ads</strong>
                      </div>
                    </draggable>
                  </div>
                </div>
              </draggable>

              <div class="add-row" @click="addRow">
                Add Row
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="layout-picker">

              <div class="cards-wrapper">
                <div class="search-wrapper">
                  <input type="text" placeholder="Find card..." v-model="queryCards" @keyup.13="search"/>
                  <div class="btn btn-search-card" @click="search">
                    <i class="fas fa-search"></i>
                  </div>
                </div>

                <div class="card-type">
                  <select v-model="cardTypeSelected" @change="getCardsType">
                    <option value="video">Videos</option>
                    <option value="playlist">Playlists</option>
                    <option value="podcast">Podcasts</option>
                    <option value="contest">Web Link</option>
                    <option value="station">Stations</option>
                    <option value="media">Episodes</option>
                    <option value="artist">Artist</option>
                    <option value="livestream">Livestreams</option>
                    <option value="show">Shows</option>
                    <option value="show-episode">Show Episodes</option>
                    <option value="moment">Moments</option>
                  </select>

                  <select v-model="cardsizeSelected" @change="getCardsType">
                    <option value="all">All Images</option>
                    <option value="1000x1100">Card Images</option>
                    <option value="750x750">Row Images</option>
                  </select>
                </div>

                <div class="cards-right-outer">
                  <draggable v-model="cards" class="cards-right card-area"
                             :options="{group:{ name:'newCard',  pull:'clone', put:false }}"
                             @start="showAlert = true"
                             :move="editCardAfterDragged">
                    <div class="card-wrapper" v-for="card in cards" :key="card._id">
                      <div class="card-img">
                        <img :src="card.img[0]['750x750']" alt="" v-if="card.img[0]['750x750']" />
                        <img :src="card.img[0]['1000x1100']" alt="" v-if="!card.img[0]['750x750']" />
                      </div>
                      <div class="card-title" v-if="card.titleSpanish || card.titleEnglish">
                        {{card.titleSpanish ? card.titleSpanish : card.titleEnglish}}
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>

    <b-modal id="editRow" ref="editRow" ok-title="ok" size="md"
             title="Row Details" @ok="handleOk" @cancel="handleCancel"
             :hide-header-close="true"
             :no-close-on-backdrop="true"
             :no-close-on-esc="true">
      <div class="row modal-wrapper">
        <div class="col-6">
          <label>English Title</label>
          <input type="text" v-model="rowToEdit.title.en"/>
        </div>
        <div class="col-6">
          <label>Spanish Title</label>
          <input type="text" v-model="rowToEdit.title.es"/>
        </div>

        <div class="col-6">
          <b-form-checkbox v-model="rowToEdit.hide_app" name="check-button" switch>
            Hide on iOS and Android Apps
          </b-form-checkbox>
        </div>

        <div class="col-12">
          <label>Row Type</label>
          <select v-model="rowToEdit.type">
            <option value="cards" v-if="rowToEdit.type === 'cards'">Featured Cards</option>
            <option value="carousel" v-if="rowToEdit.type !== 'cards'">Carousel</option>
            <option value="grid" v-if="rowToEdit.type !== 'cards'">Grid</option>
            <option value="ads" v-if="rowToEdit.type !== 'cards'">Ads Row</option>
          </select>
          <br />
          <ul>
            <li><strong>Carousel</strong>: This will activate a slider.</li>
            <li><strong>Grid</strong>: This will create a grid of images that do not paginate.</li>
            <li><strong>Ads Row</strong>: This is to set up a parallax creative.</li>
          </ul>
        </div>

        <!-- <div class="col-12" v-if="rowToEdit.type !== 'ads'">
          <label>Automated Type</label>
          <select v-model="rowToEdit.automated">
            <option value="none">Select Automated Type</option>
            <option v-for="(metadata, type) in automated_row_types" :value="type" :key="type">{{metadata.title}} (Automated)</option>
          </select>
        </div> -->

        <div class="col-12" v-if="rowToEdit.type !== 'ads'" style="margin-top: 2em; margin-bottom: 2em;">
          <label>Images Type</label>
          <select v-model="rowToEdit.image_type" @change="checkCardSizes">
            <option value="landscape">Landscape</option>
            <option value="square">Square</option>
          </select>
          <ul>
            <li><strong>Landscape</strong>: This will use the wider 1920x850 images.</li>
            <li><strong>Square</strong>: This will use the square 750x750 images.</li>
          </ul>
        </div>

        <div class="col-6" v-if="rowToEdit.type !== 'ads'">
          <label>Items Desktop Count</label>
          <!-- <input type="numer" v-model.number="rowToEdit.items_desktop_count"/> -->
          <select v-model.number="rowToEdit.items_desktop_count">
            <option :value="1">1</option>
            <option :value="2">2</option>
            <option :value="3">3</option>
            <option :value="4">4</option>
          </select>
          <small>Maximum amount of items on desktop before the row wraps or carousel slides</small>
        </div>

        <div class="col-6" v-if="rowToEdit.type !== 'ads'">
          <label>Items Mobile Count</label>
          <!-- <input type="number" v-model.number="rowToEdit.items_mobile_count"/> -->
          <select v-model.number="rowToEdit.items_mobile_count">
            <option :value="1">1</option>
            <option :value="2">2</option>
          </select>
          <small>Maximum amount of items on mobile before the row wraps or carousel slides</small>
        </div>

        <br />
        <br />

        <div class="col-12" v-if="rowToEdit.type !== 'ads'">
          <label>Display titles underneath each cell?</label>
          <select v-model="rowToEdit.displayTitles">
            <option :value="true">Show Titles</option>
            <option :value="false">Hide Titles</option>
          </select>
        </div>
      </div>
    </b-modal>

    <b-modal id="confirmRemoveRow" ref="confirmRemoveRow" size="md" title="Confirm" @ok="removeRow">
      <div class="row modal-wrapper">
        <div class="col-12">
          Are you sure want to remove it?
        </div>
      </div>
    </b-modal>



    <b-modal id="modalStationProgramming" :ok-only="true" ok-title="close" size="xl" title="Programming">
      <b-tabs v-if="station.programming" v-model="tabIndex">
        <b-tab  v-for="(value, day, index) in station.programming.times" :key="index" :title="day" :title-link-class="linkClass(index)">
          <div v-for="(program, $i) in value" :key="$i">
            <div class="row">
              <div class="col-2">
                <label>Title</label>
              </div>
              <div class="col-1">
                <label>From</label>
              </div>
              <div class="col-1">
                <label>To</label>
              </div>
              <div class="col-4">
                <label>Live image URL</label>
              </div>
              <div class="col-4">
                <label>Card Image URL</label>
              </div>
            </div>
            <div class="row">
              <div class="station-row-wrapper">
                <div class="col-2">
                  <input type="text" placeholder="Title" v-model="program.radio_name"/>
                </div>
                <div class="col-1">
                  <input type="number" placeholder="From" v-model="program.from"/>
                </div>
                <div class="col-1">
                  <input type="number" placeholder="To" v-model="program.to"/>
                </div>
                <div class="col-4">
                  <img class="station-time-img" :src="program.live_img_url" v-if="program.live_img_url">
                  <b-form-file v-model="program.live_img_url" @change.native="uploadStationProgrammingImage($event, 180, 150, program, 'live_img_url')" placeholder="Thumbnail image 180x150"></b-form-file>
                  <input type="text" placeholder="Live image URL" v-model="program.live_img_url"/>
                </div>
                <div class="col-3">
                  <img class="station-time-img" :src="program.card" v-if="program.card">
                  <b-form-file v-model="program.card" @change.native="uploadStationProgrammingImage($event, 1000, 1100, program, 'card')" placeholder="Card image 1000x1100"></b-form-file>
                  <input type="text" placeholder="Card image URL" v-model="program.card"/>
                </div>
                <div class="col-1">
                  <button type="button" class="btn-program btn-delete" @click="removeProgram(day, $i)">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label>Card Video URL</label>
              </div>
              <div class="col-4">
                <label>Stream image URL</label>
              </div>
              <div class="col-3">
                <label>Stream URL</label>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <input type="text" placeholder="Card video URL" v-model="program.card_video_url"/>
              </div>
              <div class="col-4">
                <input type="text" placeholder="Stream image URL" v-model="program.stream_img_url"/>
              </div>
              <div class="col-3">
                <input type="text" placeholder="Stream URL" v-model="program.stream_url"/>
              </div>
            </div>
            <hr />
          </div>
          <div class="row">
            <div class="col-12">
              <button type="button" class="btn-program" @click="addProgram(day)">Add Program</button>
              <button type="button" class="btn-program" @click="duplicateProgram(day)">Copy Grid</button>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal id="modalStationBuckets" ref="modalStationBuckets"  :ok-only="true" ok-title="close" size="xl" title="Images">
      <div class="modal-wrapper model-buckets">
        <section v-for="bucket in buckets">
          <h3>Bucket #{{bucket.bucketNumber}}</h3>
          <article v-for="image in bucket.images" @click="openStationBucketImageModal(image)" class="bucket-image">
            <strong>Image #{{image.order}}</strong>
            <img :src="image['2xImageURL']" />
            <small>
              ID: <code>{{image._id}}</code>
            </small>
          </article>
        </section>
      </div>
    </b-modal>

    <b-modal id="modalStationBucketImage" ref="modalStationBucketImage"  :ok-only="true" ok-title="close" size="xl" title="Bucket Image">
      <div class="modal-wrapper model-bucket-image">
        <b-form-row class="row-form">
          <b-col>
            <p>ID: <code>{{bucketImage._id}}</code></p>
            <p>Created At: {{bucketImage.createdAt}}</p>
            <p>Updated At: {{bucketImage.updatedAt}}</p>

            <b-form-group label="Duration">
              <b-form-input id="bucketImageDuration" type="number" v-model="bucketImage.duration">
              </b-form-input>
            </b-form-group>

            <b-form-group label="Order">
              <b-form-input id="bucketImageOrder" type="number" v-model="bucketImage.order">
              </b-form-input>
            </b-form-group>

            <b-form-group label="Interpolation">
              <b-form-input id="bucketImageInterpolation" type="text" v-model="bucketImage.interpolation">
              </b-form-input>
            </b-form-group>

            <b-form-row class="row-form">
              <b-col>
                <b-form-group label="Starting X">
                  <b-form-input id="bucketImageStartingX" type="number" v-model="bucketImage.startingX">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Starting Y">
                  <b-form-input id="bucketImageStartingY" type="number" v-model="bucketImage.startingY">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row class="row-form">
              <b-col>
                <b-form-group label="Pan X">
                  <b-form-input id="bucketImagePanX" type="number" v-model="bucketImage.panX">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Pan Y">
                  <b-form-input id="bucketImagePanY" type="number" v-model="bucketImage.panY">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row class="row-form">
              <b-col>
                <b-form-group label="Pan X">
                  <b-form-input id="bucketImageStartAlpha" type="number" v-model="bucketImage.startAlpha">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Pan Y">
                  <b-form-input id="bucketImageEndAlpha" type="number" v-model="bucketImage.endAlpha">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row class="row-form">
              <b-col>
                <b-form-group label="Start Zoom">
                  <b-form-input id="bucketImageStartZoom" type="number" v-model="bucketImage.endZoom">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="End Zoom">
                  <b-form-input id="bucketImageEndZoom" type="number" v-model="bucketImage.endZoom">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-button type="button" @click="deleteBucketImage(bucketImage)" block>
              Delete Bucket Image
            </b-button>
          </b-col>
          <b-col>
            <p>2x Image URL</p>
            <img :src="bucketImage['2xImageURL']" alt="">
            <p>3x Image URL</p>
            <img :src="bucketImage['3xImageURL']" alt="">
            <p>MDPI Image URL</p>
            <img :src="bucketImage.mdpiImageURL" alt="">
            <p>HDPI Image URL</p>
            <img :src="bucketImage.hdpiImageURL" alt="">
            <p>XHDPI Image URL</p>
            <img :src="bucketImage.xhdpiImageURL" alt="">
            <p>XXHDPI Image URL</p>
            <img :src="bucketImage.xxhdpiImageURL" alt="">
            <p>XXXHDPI Image URL</p>
            <img :src="bucketImage.xxxhdpiImageURL" alt="">
          </b-col>
        </b-form-row>
      </div>
    </b-modal>

    <b-modal id="modalStationImages" :ok-only="true" ok-title="close" size="lg" title="Images">
      <div class="row modal-wrapper">
        <div class="col-6" v-for="platform in platforms" v-bind:key="platform.platform">
          <upload @imgUploaded="imgUploaded" :data="platform"></upload>
        </div>
        <div class="col-6">
          <upload @imgUploaded="logoUploaded" :data="logo"></upload>
        </div>
        <div class="col-6">
          <upload @imgUploaded="carplayLogoUploaded" :data="carplayLogo"></upload>
        </div>

        <div class="col-6">
          <upload @imgUploaded="playerMobileUpload" :data="playerMobile"></upload>
        </div>
        <div class="col-6">
          <upload @imgUploaded="playerTabletUpload" :data="playerTablet"></upload>
        </div>
        <div class="col-6">
          <upload @imgUploaded="phonePlayerUpload" :data="phonePlayer"></upload>
        </div>
        <div class="col-6">
          <upload @imgUploaded="variableLogoUpload" @onFieldUpdate="fieldUpdated" :data="variableLogo"></upload>
        </div>
        <div class="col-6">
          <upload-svg @imgUploaded="svgUploaded" :data="svg"></upload-svg>
        </div>

        <div class="col-12">
          <upload @imgUploaded="playerBackgroundUpload" :data="webTopPlayerBackground"></upload>
        </div>

        <div class="col-6">
          <p>
            <strong>Station Video Loop</strong><br/>
            <span>Video loop used once the user play a radio station</span>
          </p>
          <loader-inline v-if="video.loading"/>
          <div class="video-holder-card">
            <div class="remove-video-card" v-if="video.url" @click="handleRemoveVideo()">
              <i class="fas fa-times"></i>
            </div>
            <div class="card-image" v-if="video.url" v-html="videoHTML"></div>
          </div>
          <b-form-file accept="video/mp4,video/*" ref="videoInput" v-model="video.file" :placeholder="video.placeholder" @change.native="handleVideoUpload($event)"></b-form-file>
        </div>
      </div>
    </b-modal>

    <b-modal id="modalStationMeta" :ok-only="true" @ok="validateMetaForm"  ok-title="close" size="lg" title="Edit Station Metadata">
      <station-meta-form :station="station" :pencilError="pencilError" />
    </b-modal>

    <b-modal id="duplicateProgram" ref="duplicateProgram" :ok-only="true" ok-title="Duplicate" @ok="handleDuplicateDayConfirm" size="md" title="Duplicate Program To Days">
      <div class="row modal-wrapper">
        <div class="col-12 duplicate-program">
          <p>Please select which days to copy to.</p>
          <ul>
            <li v-for="(isGoingToCopy, day, index) in programmingDayClipboard" v-bind:key="index" @click="handleDuplicateDaySelected(day)" v-bind:class="{selected: isGoingToCopy}">
              {{day}}
            </li>
          </ul>
        </div>
      </div>
    </b-modal>

    <b-modal id="podcastsModal" ref="podcastsModal" :ok-only="true" ok-title="Add Podcast" size="md" title="Related Podcasts">
      <div class="row modal-wrapper modal-wrapper-podcasts">
        <div class="col-12" v-for="(podcast, index) in podcasts" v-bind:key="podcast._id" v-if="podcast.published && hasPodcastPermissions">
          <div class="podcasts" :class="{'selected': podcast.selected}" @click="changeStatus(podcast, index)">
            <img :src="podcast.meta.exploreImagery.hdpiImageURL" alt="" />
            <div class="title">{{podcast.title}}</div>
          </div>
        </div>
        <div class="col-12" v-if="!hasPodcastPermissions">
          <p>You currently do not have permissions to associate podcasts to this station.  Please contact your administrator.</p>
        </div>
      </div>
    </b-modal>

     <b-modal id="livestreamsModal" ref="livestreamsModal" :ok-only="true" ok-title="Add Livestream" size="md" title="Related Livestreams">
      <div class="row modal-wrapper modal-wrapper-podcasts">
        <div class="col-12" v-for="(livestream, index) in livestreams" v-bind:key="livestream._id">
          <div class="podcasts" :class="{'selected': livestream.selected}" @click="changeStatusLivestreams(livestream, index)">
            <img :src="livestream.img" alt="" />
            <div class="title">{{livestream.titleSpanish}}</div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal id="confirmCancelModal" ref="confirmCancelModal" size="md" title="Confirmation" @ok="cancelAction">
      <div class="row modal-wrapper">
        <div class="col-12">
          Are you sure you want to cancel this page?
        </div>
      </div>
    </b-modal>

    <b-modal id="modalLightBox" ref="modalLightBox" size="xl" title="Lightbox" @close="clearLightBoxData" @cancel="closeLightBoxModal" @ok="updateStationLightBox($event)">
      <div class="modal-wrapper model-buckets">
        <div class="row mt-3">
          <div class="col">
            <div class="layout-picker pt-4 px-2" style="min-height: 230px">
              <div class="row-title ml-2">
                Selected Card
              </div>
              <div v-if="lightboxSelectedCard">
                <div class="card-wrapper mr-0" style="max-width: 100%">
                  <div class="card-img">
                    <img :src="lightboxSelectedCard.img[0]['1920x850']" alt="" v-if="lightboxSelectedCard.img[0]['1920x850']"/>
                  </div>
                  <div class="card-title" v-if="lightboxSelectedCard.titleSpanish || lightboxSelectedCard.titleEnglish">
                    {{ lightboxSelectedCard.titleSpanish ? lightboxSelectedCard.titleSpanish : lightboxSelectedCard.titleEnglish }}
                  </div>
                  <div class="card-title font-weight-normal" v-if="lightboxSelectedCard.available">
                    <span v-if="lightboxSelectedCard.available.start"><strong>From</strong>: {{formatDate(lightboxSelectedCard.available.start)}} - </span>
                    <span v-if="lightboxSelectedCard.available.end"><strong>To</strong>: {{formatDate(lightboxSelectedCard.available.end)}}</span>
                  </div>
                </div>
              </div>
              <div v-else>
                <b-alert variant="warning" show>Please, pick a card from the list.</b-alert>
              </div>
            </div>

            <div class="d-flex align-content-center mt-3">
              <label class="switch mr-1">
                <input type="checkbox" v-model="lightboxPublished">
                <span class="slider"></span>
              </label>
              <div class="video-labels font-weight-bold">Published</div>
            </div>
          </div>
          <div class="col">
            <div class="layout-picker">
              <div class="cards-wrapper">
                <div class="search-wrapper">
                  <input type="text" placeholder="Find web link cards" v-model="lightboxQueryCards" @keyup.13="searchWeblinkCards"/>
                  <div class="btn btn-search-card" @click="searchWeblinkCards">
                    <i class="fas fa-search"></i>
                  </div>
                </div>
                <div class="cards-right-outer">
                  <div class="cards-right card-area">
                    <div class="card-wrapper" v-for="card in lightboxCards" :key="card._id" @click="selectLightboxCard(card)">
                      <div class="card-img">
                        <img :src="card.img[0]['750x750']" alt="" v-if="card.img[0]['750x750']"/>
                        <img :src="card.img[0]['1000x1100']" alt="" v-if="!card.img[0]['750x750']"/>
                      </div>
                      <div class="card-title" v-if="card.titleSpanish || card.titleEnglish">
                        {{ card.titleSpanish ? card.titleSpanish : card.titleEnglish }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>


<script>
  import moment from 'moment'
  import axios from 'axios'
  import upload from '../../share/UploadImage'
  import UploadSvg from '../../share/UploadSvg'
  import draggable from 'vuedraggable'
  import StationMetaForm from './StationMetaForm'
  import {DateTime as LuxonDateTime} from 'luxon'
  import Editors from '../../share/Editors'
  import LoaderInline from '../../share/LoaderInline'

  export default {
    name: 'StationsEdit',
    components: {
      StationMetaForm,
      upload,
      UploadSvg,
      draggable,
      Editors,
      LoaderInline
    },
    props: ['card'],
    data () {
      return {
        stationID: null,
        video: {
          loading: false,
          file: null,
          url: null,
          placeholder: 'Upload Video .MP4 or .MOV'
        },
        hasPodcastPermissions: false,
        stationProgrammingCopy: {
          1: 'Monday',
          2: 'Tuesday',
          3: 'Wednesday',
          4: 'Thursday',
          5: 'Friday',
          6: 'Saturday',
          7: 'Sunday'
        },
        programmingDayClipboard: {
          Monday: false,
          Tuesday: false,
          Wednesday: false,
          Thursday: false,
          Friday: false,
          Saturday: false,
          Sunday: false
        },
        station: {},
        image: '',
        logo: {
          label: 'Logo Square - 250x250px',
          description: 'This will be the station logo on LaMusica web',
          isLoading: false,
          width: 250,
          height: 250,
          url: '',
          uploadURL: '/stations/image',
          isDimensionVariable : false
        },
        carplayLogo: {
          label: 'Carplay/Android Auto Logo - 200x200px',
          description: 'This image will show up in android auto or apple carplay',
          isLoading: false,
          width: 200,
          height: 200,
          url: '',
          uploadURL: '/stations/image',
          isDimensionVariable : false
        },
        playerMobile: {
          label: 'Player Background(Mobile) - 1618x2880px',
          description: 'This will be used as the player background of LaMusica app on android/ios phones',
          isLoading: false,
          width: 1618,
          height: 2880,
          url: '',
          uploadURL: '/stations/image',
          isDimensionVariable : false
        },
        playerTablet: {
            label: 'Player Background(Tablet) - 640x960px',
            description: 'This will be used as the player background of LaMusica app for tablets',
            isLoading: false,
            width: 640,
            height: 960,
            url: '',
            isDimensionVariable : false,
            uploadURL: '/stations/image'
        },
        variableLogo: {
            label: 'Variable Logos',
            description: 'These are transparent logos that are used throughout the app.',
            isLoading: false,
            width: 0,
            height: 0,
            isDimensionVariable : true,
            url: '',
            uploadURL: '/stations/image'
        },
        phonePlayer: {
            label: 'Mobile Player 1000x500',
            description: 'This image will appear in the player in android and ios',
            isLoading: false,
            width: 1000,
            height: 500,
            isDimensionVariable : false,
            url: '',
            uploadURL: '/stations/image'
        },
        webTopPlayerBackground: {
          label: 'Web Top Strip Player Background',
          description: 'This will be used as the top player background of LaMusica web.',
          isLoading: false,
          width: 1920,
          height: 120,
          isDimensionVariable : false,
          url: '',
          uploadURL: '/stations/image'
        },
        platforms: {
          android: {
            label: 'Android - 1296x560px',
            description: 'This will be used on the playlists section of LaMusica app/web',
            platform: 'android',
            isLoading: false,
            width: 1296,
            height: 560,
            isDimensionVariable : false,
            url: ''
          },
          ios: {
            label: 'iOS - 1092x432px',
            description: 'This will be used on the playlists section of LaMusica app/web',
            platform: 'ios',
            isLoading: false,
            width: 1092,
            height: 432,
            isDimensionVariable : false,
            url: ''
          },
          exploreSquare: {
            label: 'Detail - 1440x1440px',
            platform: 'exploreSquare',
            description: 'This will be used on the detail view (app/web) and homepage card rows (app)',
            isLoading: false,
            width: 1440,
            height: 1440,
            isDimensionVariable : false,
            url: ''
          },
          landing: {
            label: 'Featured Header - 1920x850',
            platform: 'landing',
            description: 'This will be used on the tv and homepage website rotators',
            isLoading: false,
            width: 1920,
            height: 850,
            isDimensionVariable : false,
            url: ''
          },
        },
        svg: {
          label: 'Upload SVG',
          description: 'This will be used on the Station section of LaMusica app/web',
          uploadURL: '/stations/image?type=svg',
          isDimensionVariable : false,
          url: ''
        },
        tabIndex: 0,
        buckets: [],
        bucketImage: {},
        rowIndexToEdit: null,
        rowToEdit: {
          type: 'carousel', // 'cards' || 'grid'
          title: {
            es: 'Spanish Title',
            en: 'English Title'
          },
          automated: 'none',
          displayTitles: false,
          items_desktop_count: 4,
          hide_app: false,
          items_mobile_count: 2,
          image_type: 'square',
          items: []
        },
        cardTypeSelected: 'video',
        cardsizeSelected: 'all',
        queryCards: '',
        cards: [],
        lightboxCards: [],
        lightboxQueryCards: '',
        lightboxSelectedCard: null,
        lightboxPublished: false,
        formData: new FormData(),
        rowIndexToDelete: 0,
        podcasts: [],
        livestreams: [],
        pencilError: null
      }
    },
    computed: {
      videoHTML: function () {
        return `<video controls><source src="${this.video.url}" type="video/mp4"><source src="${this.video.url}" type="video/mov"></video>`
      }
    },
    mounted () {
      this.stationID = this.$route.params.id

      this.getCards()
        .then((res) => {
          this.cards = res.data
        })

      axios.all([this.$http.get(`/stations/${this.stationID}`)])
        .then(axios.spread((station) => {
          this.station = station.data

          if (!this.station.published) {
            this.$set(this.station, 'published', false)
          }

          if(!this.station.chat){
            this.station.chat = {}
            this.station.chat.id = ''
            this.station.chat.isEnabled = false
            this.station.chat.welcomeMessage = {}
            console.log('set default chat values', this.station.chat);
          }
          if(!this.station.chat.welcomeMessage){
            this.station.chat.welcomeMessage={}
          }

          if(!this.station.chatBannerAd){
            this.station.chatBannerAd = {}
            this.station.chatBannerAd.tag = ''
            this.station.chatBannerAd.isDisabled = false
          }

          this.logo.url = station.data.logo_web_square_image || ''
          this.svg.url = station.data.svg || ''

          if (this.station.logo){
            this.variableLogo.url = this.station.logo.url
            this.variableLogo.height= this.station.logo.height
            this.variableLogo.width= this.station.logo.width
          }else{
            this.station.logo = {}
            this.variableLogo.url = ''
            this.variableLogo.height= 330
            this.variableLogo.width= 0
          }

          if(!this.station.carplayLogo){
            this.station.carplayLogo = {url:''}
          }else{
            this.carplayLogo.url = this.station.carplayLogo.url
          }

          if(!this.station.player){
            this.station.player = {}
          }
          this.playerMobile.url = this.station.player.mobile || ''
          this.playerTablet.url = this.station.player.tablet || ''
          this.phonePlayer.url = this.station.player.phone || ''
          this.webTopPlayerBackground.url = this.station.player.webTopBackground || ''

          if (this.station.videoUrl) {
            this.video.url = this.station.videoUrl
          }

          if (!this.station.sponsored) {
            this.station.sponsored = {
              start: LuxonDateTime.local().toISO(),
              end: LuxonDateTime.fromObject({ year: 2025, month: 12, day: 31, hour: 0, minute: 0 }).toISODate()
            }
          }

          if(!this.station.disableAds){
            this.station.disableAds= false
          }

          if (!this.station.web_ads) {
            this.station['web_ads'] = {
              '300x250': [{
                tag: '',
                start: null,
                end: null
              },
              {
                tag: '',
                start: null,
                end: null
              }],
              '970x90': [{
                tag: '',
                start: null,
                end: null
              }],
              '1270x150': [{
                tag: '',
                disable: false,
                start: LuxonDateTime.local().toISO(),
                end: LuxonDateTime.fromObject({ year: 2025, month: 12, day: 31, hour: 0, minute: 0 }).toISODate()
              }],
              '1270x150_ALT': [{
                tag: '',
                disable: true,
                start: LuxonDateTime.local().toISO(),
                end: LuxonDateTime.fromObject({ year: 2025, month: 12, day: 31, hour: 0, minute: 0 }).toISODate()
              }],
              '1x1': [{
                tag: '',
                start: LuxonDateTime.local().toISO(),
                end: LuxonDateTime.fromObject({ year: 2025, month: 12, day: 31, hour: 0, minute: 0 }).toISODate()
              }]
            }
          }

          if (!this.station.web_ads['728x180']) {
            this.station['web_ads']['728x180'] = [{
                tag: '',
                disable:false,
                start: null,
                end: null
              }]
          }

          if (!this.station.web_ads['320x100']) {
            this.station['web_ads']['320x100'] = [{
                tag: '',
                disable:false,
                start: null,
                end: null
              }]
          }

          if (!this.station.web_ads['1270x150']) {
            this.station['web_ads']['1270x150'] = [{
              tag: '',
              disable: false,
              start: LuxonDateTime.local().toISO(),
              end: LuxonDateTime.fromObject({ year: 2025, month: 12, day: 31, hour: 0, minute: 0 }).toISODate()
            }]
          }

          if (!this.station.web_ads['1270x150'][0].disable) {
            this.station['web_ads']['1270x150'][0].disable = false
          }

          if (!this.station.web_ads['1270x150_ALT']) {
            this.station['web_ads']['1270x150_ALT'] = [{
              tag: '',
              disable: true,
              start: LuxonDateTime.local().toISO(),
              end: LuxonDateTime.fromObject({ year: 2025, month: 12, day: 31, hour: 0, minute: 0 }).toISODate()
            }]
          }

          if (!this.station.web_ads['1x1']) {
            this.station['web_ads']['1x1'] = [{
              tag: '',
              start: LuxonDateTime.local().toISO(),
              end: LuxonDateTime.fromObject({ year: 2025, month: 12, day: 31, hour: 0, minute: 0 }).toISODate()
            }]
          }

          if (!this.station.web_ads['468x60']) {
            this.station['web_ads']['468x60'] = [{
              tag: '',
              disable:false,
              start: null,
              end: null
            }]
          }

          if (!this.station.broadcastFrequency) {
            this.station.broadcastFrequency = null
          }

          if (!this.station.address) {
            this.station.address = null
          }

          const obj = {}
          // Making sure we have the right days order
          for (const keyMap in this.stationProgrammingCopy) {
            for (const key in this.station.programming.times) {
              if (this.stationProgrammingCopy[keyMap] === key) {
                obj[key] = this.station.programming.times[key]
              }
            }
          }
          this.station.programming.times = obj

          this.platforms.android.url = (this.station.exploreImagery && this.station.exploreImagery.xxxhdpiImageURL) ? this.station.exploreImagery.xxxhdpiImageURL : ''
          this.platforms.ios.url = (this.station.exploreImagery && this.station.exploreImagery['3xImageURL']) ? this.station.exploreImagery['3xImageURL'] : ''
          this.platforms.exploreSquare.url = (this.station.detailViewImagery && this.station.detailViewImagery.xxxhdpiImageURL) ? this.station.detailViewImagery.xxxhdpiImageURL : ''
          this.platforms.landing.url = (this.station.landing && this.station.landing['3xImageURL']) ? this.station.landing['3xImageURL'] : ''

          if (this.station.podcasts) {
            this.getPodcasts().then((podcastsResponse) => {
              this.hasPodcastPermissions = true
              podcastsResponse.data.podcasts.forEach((podcast) => {
                const podcastExists = this.station.podcasts.indexOf(podcast._id) > -1
                this.$set(podcast, 'selected', podcastExists)
                this.podcasts.push(podcast)
              })
            }).catch((e) => {
              console.log(e)
            })
          }

          if (!this.station.livestreams) {
            this.station.livestreams = []
          }

          if (this.station.livestreams) {
            this.getLivestreams().then((livestreamsResponse) => {
              livestreamsResponse.data.forEach((livestream) => {
                const livestreamExist = this.station.livestreams.indexOf(livestream._id) > -1
                this.$set(livestream, 'selected', livestreamExist)
                this.livestreams.push(livestream)
              })
            }).catch((e) => {
              console.log(e)
            })
          }

          if(!this.station.mobile_hex_color) {
            this.station.mobile_hex_color = '#ffffff'
          }

        }))
        .catch((err) => {
          console.log(err)
        })

        console.log('ste', this.station);
    },
    methods: {

      validateMetaForm ($event) {
        if(this.station.web_ads['1270x150'][0].disable === false && this.station.web_ads['1270x150_ALT'][0].disable === false) {
          $event.preventDefault()

          this.pencilError = 'Only one pencil banner can be active'

          this.$notify({
            group: 'notifications',
            title: this.pencilError,
            text: '',
            type: 'error'
          })

        }else{
          this.pencilError = null
          this.$bvModal.hide('modalStationMeta')
        }
      },

      handleVideoUpload ($event) {
        const input = $event.target
        const formData = new FormData()
        const file = input.files && input.files[0]
        if (!file) {
          return
        }

        const hasValidExtension = /\.(mp4|mov)$/i.test(file.name)
        if (!hasValidExtension) {
          return this.$notify({
            group: 'notifications',
            title: 'Video should be mp4 or mov format :(',
            text: '',
            type: 'error'
          })
        }

        const fileSize = file.size / 1024 / 1024
        const isTooLargeUpload = fileSize > 12
        if (isTooLargeUpload) {
          return this.$notify({
            group: 'notifications',
            title: 'Video File size exceeds 12 MB :)',
            text: '',
            type: 'error'
          })
        }

        formData.append('file', file)

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }

        this.video.loading = true
        this.$http.post('/stations/video', formData, config).then((res) => {
          if (res.success) {
            this.video.url = res.data[0]
            this.station.videoUrl = this.video.url
          } else {
            this.$notify({
              group: 'notifications',
              title: 'Video file is required :)',
              text: '',
              type: 'error'
            })
          }
        }).catch((err) => {
          console.log(err)
        }).finally(() => {
          this.video.loading = false
        })
      },
      handleRemoveVideo () {
        this.video.url = null
        this.video.file = null
        this.station.videoUrl = null
      },
      addProgram (day) {
        this.station.programming.times[day].push({
          from: null,
          to: null,
          radio_name: null,
          live_img_url: null
        })
      },
      handleDuplicateDaySelected (day) {
        this.programmingDayClipboard[day] = true
      },
      handleDuplicateDayConfirm () {
        const currentDay = Object.keys(this.station.programming.times)[this.tabIndex]
        for (let day in this.programmingDayClipboard) {
          if (this.programmingDayClipboard[day]) {
            this.station.programming.times[day] = this.station.programming.times[currentDay]
            this.programmingDayClipboard[day] = false
          }
        }
      },
      duplicateProgram () {
        this.$refs.duplicateProgram.show()
      },
      linkClass (idx) {
        if (this.tabIndex === idx) {
          return ['tab-title-active', 'tab-title']
        } else {
          return ['tab-title']
        }
      },
      removeProgram (key, index) {
        this.station.programming.times[key].splice(index, 1)
      },
      updateStation () {
        if (this.station.published) {
          if ( (!this.station.streamURL || this.station.streamURL === '') && (!this.station.streamURLHls || this.station.streamURLHls === '') ) {
            return this.$notify({
            group: 'notifications',
            title: 'Stream URL is required :(',
            text: '',
            type: 'error'
          })
          }

          let valid = this.validateProgramming()
          if (!valid) {
            this.$notify({
              group: 'notifications',
              title: 'All programs are required :(',
              text: '',
              type: 'error'
            })
            return false
          }

        let logoState = this.isLogoObjectValid()
        switch(logoState){
          case 1:
            this.$notify({
                group: 'notifications',
                title: 'A Variable logo is required',
                text: '',
                type: 'error'
              })
            return false;
          case 2:
            this.$notify({
                group: 'notifications',
                title: 'Width for Variable logo is required',
                text: '',
                type: 'error'
              })
            return false;
          case 3:
            this.$notify({
                group: 'notifications',
                title: 'Height for Variable logo is required',
                text: '',
                type: 'error'
              })
            return false;
        }

        if(!this.station.player.mobile){
          this.$notify({
                group: 'notifications',
                title: 'Player Background(Mobile) is required',
                text: '',
                type: 'error'
              })
            return false;
        }
        if(!this.station.player.tablet){
          this.$notify({
                group: 'notifications',
                title: 'Player Background(Tablet) is required',
                text: '',
                type: 'error'
              })
            return false;
        }

        if(!this.station.carplayLogo.url){
          this.$notify({
                group: 'notifications',
                title: 'Carplay/Android Auto image is required',
                text: '',
                type: 'error'
              })
            return false;
        }

        if (!this.station.videoUrl || (this.station.videoUrl === '')) {
          return this.$notify({
            group: 'notifications',
            title: 'Video Loop is required :(',
            text: '',
            type: 'error'
          })
        }

        if (this.station.chat.isEnabled){
          if(this.station.chat.id === ''){
            return this.$notify({
            group: 'notifications',
            title: 'Chat id is required :(',
            text: '',
            type: 'error'
          })
          }
        }

        let allRowsAreValid = true
        let adsRowIsValid = true

          for (let index = 0; index < this.station.layout.length; index++) {
            const row = this.station.layout[index]

            if (row.type === 'ads' && (row.items.length === 0) && (row.url === '')) {
              adsRowIsValid = false
              break
            }

            if (row.items.length === 0) {
              if (row.type === 'ads') {
                continue
              }
              allRowsAreValid = false
              break
            }
          }

          if (!adsRowIsValid) {
            return this.$notify({
              group: 'notifications',
              title: 'At least one card or a URL is required on the Ads row :(',
              text: '',
              type: 'error'
            })
          }

          if (!allRowsAreValid) {
            return this.$notify({
              group: 'notifications',
              title: 'At least one card is required per row :(',
              text: '',
              type: 'error'
            })
          }
        }

        for (const key in this.station.programming.times) {
          if (this.station.programming.times.hasOwnProperty(key)) {
            this.station.programming.times[key].forEach((day) => {
              day.from = parseInt(day.from)
              day.to = parseInt(day.to)
            })
          }
        }

        if (this.station.programming && this.station.programming.offset) {
          this.station.programming.offset = parseInt(this.station.programming.offset)
        }

        this.$http.put('/stations', {station: this.station})
          .then((res) => {
            if (res.success) {
              this.$notify({
                group: 'notifications',
                title: 'Station updated successfully :)',
                text: '',
                type: 'success'
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      validateProgramming () {
        let alert = false
        for (const day in this.station.programming.times) {
          if (this.station.programming.times[day]) {
            this.station.programming.times[day].forEach(item => {
              for (const key in item) {
                if (item[key] === null) {
                  alert = true
                }
              }
            })
          }
        }

        if (alert) {
          return false
        }

        return true
      },
      isLogoObjectValid() {
        if(this.station.logo.url && this.station.logo.url !== ''){
          if(this.station.logo.width &&this.station.logo.width>0){
            if(this.station.logo.height && this.station.logo.height>0){
              return 0;
            }else{
              return 3; //3= height not set
            }
          }else {return 2;} //2= width not set
        }else{
          return 1; // 1= url not set
        }
      },
      imgUploaded (formData, data) {
        this.image = formData
        // data.isLoading = true
        this.platforms[data.platform].isLoading = true

        this.$http.post(`/common/image?resize=${data.platform}`,
          this.image,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
          .then((res) => {
            this.platforms[res.data.device]['url'] = res.data.images[0]

            switch (res.data.device) {
              case 'android':
                this.station.exploreImagery.xxxhdpiImageURL = res.data.images[0]
                this.station.exploreImagery.xxhdpiImageURL = res.data.images[1]
                this.station.exploreImagery.xhdpiImageURL = res.data.images[2]
                this.station.exploreImagery.hdpiImageURL = res.data.images[3]
                this.station.exploreImagery.mdpiImageURL = res.data.images[4]
                break
              case 'ios':
                this.station.exploreImagery['3xImageURL'] = res.data.images[0]
                this.station.exploreImagery['2xImageURL'] = res.data.images[1]
                break
              case 'exploreSquare':
                this.station.detailViewImagery.xxxhdpiImageURL = res.data.images[0]
                this.station.detailViewImagery.xxhdpiImageURL = res.data.images[1]
                this.station.detailViewImagery.xhdpiImageURL = res.data.images[2]
                this.station.detailViewImagery.hdpiImageURL = res.data.images[3]
                this.station.detailViewImagery.mdpiImageURL = res.data.images[4]
                this.station.detailViewImagery['3xImageURL'] = res.data.images[5]
                this.station.detailViewImagery['2xImageURL'] = res.data.images[6]
                break
              case 'landing':
                this.station.landing = {}
                this.station.landing['3xImageURL'] = res.data.images[0]
                this.station.landing['2xImageURL'] = res.data.images[1]
                this.station.landing['1xImageURL'] = res.data.images[2]
                break
            }
            this.platforms[res.data.device].isLoading = false
            // data.isLoading = false
          })
          .catch((e) => {
            console.log('FAILURE!!', e)
          })
      },
      openStationBucketModal () {
        const bucketURL = `/stations/${this.stationID}/buckets`
        this.$http.get(bucketURL).then((response) => {
          this.buckets = response.data.sort((a, b) => {
            return a.bucketNumber < b.bucketNumber ? -1 : 1
          })
          const queries = this.buckets.map((bucket) => {
            const bucketImagesURL = `${bucketURL}/${bucket._id}/images`
            return this.$http.get(bucketImagesURL)
          })
          return Promise.all(queries)
        }).then((bucketImagesResponses) => {
          const bucketMap = {}
          bucketImagesResponses.forEach((bucketImageResponse) => {
            bucketImageResponse.data.forEach((bucketImage) => {
              if (!bucketMap[bucketImage.bucket_id]) {
                bucketMap[bucketImage.bucket_id] = []
              }
              bucketMap[bucketImage.bucket_id].push(bucketImage)
            })
          })

          this.buckets = this.buckets.map((bucket) => {
            bucket.images = bucketMap[bucket._id].sort((a, b) => {
              return a.order < b.order ? -1 : 1
            })
            return bucket
          })
          this.$refs.modalStationBuckets.show()
        }).catch((e) => {
          console.log('openStationBucketModal', e)
        })
      },
      openStationBucketImageModal (image) {
        this.bucketImage = image
        this.$refs.modalStationBucketImage.show()
      },
      deleteBucketImage (image) {
        const bucketURL = `/stations/${this.stationID}/buckets/${image.bucket_id}/images/${image._id}`
        this.$http.delete(bucketURL).then((response) => {
          this.$refs.modalStationBucketImage.hide()
          this.openStationBucketModal()
        }).catch((e) => {
          console.log('openStationBucketModal', e)
        })
      },
      uploadStationProgrammingImage (event, targetWidth, targetHeight, program, targetProperty) {
        const input = event.target
        const hasFile = input.files && input.files[0]
        const isJPEGorPNG = /\.(jpe?g|png)$/i.test(input.files[0].name)
        const isValidFile = hasFile && isJPEGorPNG

        if (!isValidFile) {
          this.$notify({
            group: 'notifications',
            title: 'Image file should be JPG or PNG format :(',
            text: '',
            type: 'error'
          })
          return
        }

        let reader = new FileReader()
        reader.readAsDataURL(input.files[0])

        reader.onload = (e) => {
          const URL = window.URL || window.webkitURL
          const image = new Image()
          image.src = URL.createObjectURL(input.files[0])
          image.onload = () => {
            const height = image.height
            const width = image.width

            if (width !== targetWidth || height !== targetHeight) {
              this.$notify({
                group: 'notifications',
                title: `Image size allowed is ${targetWidth}x${targetHeight}px :(`,
                text: '',
                type: 'error'
              })

              return
            }

            this.formData = new FormData()
            this.formData.append('image', input.files[0])
            this.isLoadingImage = true
            const postRequestParams = {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }

            this.$http.post('/stations/image', this.formData, postRequestParams).then((res) => {
              if (res.success) {
                program[targetProperty] = res.data[0]
                this.$notify({
                  group: 'notifications',
                  title: 'Image uploaded successfully :)',
                  text: '',
                  type: 'success'
                })
              } else {
                this.$notify({
                  group: 'notifications',
                  title: `${res.message} :(`,
                  text: '',
                  type: 'error'
                })
              }
            }).catch((err) => {
              console.log(err)
            })
          }
        }
      },
      search () {
        if (this.queryCards !== '') {
          this.$http.get('/cards', {params: {type: this.cardTypeSelected, size: this.cardsizeSelected, q: this.queryCards}})
            .then((res) => {
              this.cards = res.data
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          this.getCards().then((res) => {
            this.cards = res.data
          }).catch((err) => {
            console.log(err)
          })
        }
      },
      getCards () {
        return this.$http.get('/cards', {params: {type: this.cardTypeSelected, size: this.cardsizeSelected, page_size: 50}})
      },
      getCardsType () {
        this.getCards()
          .then((res) => {
            this.cards = res.data
            this.queryCards = ''
          })
          .catch((err) => {
            console.log(err)
          })
      },
      editCardAfterDragged (evt) {
        // console.log('evt.draggedContext.element', evt.draggedContext.element)
        // console.log('editCardAfterDragged', evt.relatedContext.component.$el.classList)
        // console.log('evt.to.classList', evt.to.classList)
        if (evt.to.classList.contains('ads-section')) {
          if (evt.relatedContext.list.length === 1) {
            return false
          }
          if (!evt.draggedContext.element.img[0]['1920x850']) {
            if (this.showAlert) {
              this.$notify({
                group: 'notifications',
                title: 'Card image of 1920x850 is required',
                text: '',
                type: 'error'
              })
            }
            this.showAlert = false
            return false
          }
          return true
        }

        if (evt.to.classList.contains('featured')) {
          if (this.station.layout[0].items.length > 11) {
            if (this.showAlert) {
              this.$notify({
                group: 'notifications',
                title: 'Sorry. Only 12 cards are allowed :(',
                text: '',
                type: 'error'
              })
            }
            this.showAlert = false
            return false
          }
        }
        if (evt.to.classList.contains('stations') && evt.draggedContext.element.type !== 'station') {
          return false
        }
        let notValid = false
        let validImgSize = this.validateImageOnDragAndDrop(evt)

        evt.relatedContext.list.forEach((item) => {
          if (item._id === evt.draggedContext.element._id) {
            notValid = true
          }
        })

        if (notValid || !validImgSize) {
          return false
        }
      },
      validateImageOnDragAndDrop (evt) {
        if (evt.to.classList.contains('landscape') && !evt.draggedContext.element.img[0]['1920x850'] && !evt.to.classList.contains('cards-right')) {
          if (this.showAlert) {
            this.$notify({
              group: 'notifications',
              title: 'Card image of 1920x850 is required',
              text: '',
              type: 'error'
            })
          }
          this.showAlert = false
          return false
        }
        if (evt.to.classList.contains('featured') && !evt.draggedContext.element.img[0]['1000x1100'] && !evt.to.classList.contains('cards-right')) {
          if (this.showAlert) {
            this.$notify({
              group: 'notifications',
              title: 'Card image of 1000x1100 is required',
              text: '',
              type: 'error'
            })
          }
          this.showAlert = false
          return false
        } else if (!evt.to.classList.contains('featured') && !evt.draggedContext.element.img[0]['750x750'] && !evt.to.classList.contains('cards-right')) {
          if (this.showAlert) {
            this.$notify({
              group: 'notifications',
              title: 'Card image of 750x750 is required',
              text: '',
              type: 'error'
            })
          }
          this.showAlert = false
          return false
        } else if (evt.to.classList.contains('carousel') && !evt.draggedContext.element.img[0]['1000x1100'] && !evt.to.classList.contains('cards-right')) {
          if (this.showAlert) {
            this.$notify({
              group: 'notifications',
              title: 'Card image of 1000x1100 is required',
              text: '',
              type: 'error'
            })
          }
          this.showAlert = false
          return false
        } else if (evt.to.classList.contains('carousel') && !evt.draggedContext.element.img[0]['750x750'] && !evt.to.classList.contains('cards-right')) {
          if (this.showAlert) {
            this.$notify({
              group: 'notifications',
              title: 'Card image of 750x750 is required',
              text: '',
              type: 'error'
            })
          }
          this.showAlert = false
          return false
        } else if (evt.to.classList.contains('automated')) {
          if (this.showAlert) {
            this.$notify({
              group: 'notifications',
              title: 'You cannot add any cards to this row, since it is an automated one',
              text: '',
              type: 'error'
            })
          }
          this.showAlert = false
          return false
        }

        return true
      },
      addRow () {
        this.$refs.editRow.show()
      },
      removeCard (rowIndex, cardIndex) {
        this.station.layout[rowIndex].items.splice(cardIndex, 1)
      },
      getCardStyle (card) {
        const style = {}
        const available = card.available
        if (available && available.start && available.end) {
          const isExpired = new Date(available.end).getTime() < Date.now()
          const willBeAvailableInFuture = new Date(available.start).getTime() > Date.now()
          if (isExpired) {
            style['border-left'] = '3px solid #f1494a'
          } else if (willBeAvailableInFuture) {
            style['border-left'] = '3px solid #e6d141'
          }
        }
        return style
      },
      checkCard (evt, index) {
        let flag = false
        let validImgSize = this.validateImageOnDragAndDrop(evt)

        evt.relatedContext.list.forEach((item) => {
          if (item._id === evt.draggedContext.element._id &&
            evt.to.getAttribute('index') !== evt.from.getAttribute('index')) {
            flag = true
          }
        })

        if (flag || !validImgSize) {
          return false
        }
      },
      editRow (row, index) {
        this.rowToEdit = JSON.parse(JSON.stringify(row))
        this.rowIndexToEdit = index
        this.$refs.editRow.show()
      },
      handleOk () {
        if (this.rowIndexToEdit !== null) {
          this.station.layout[this.rowIndexToEdit].title = {
            en: this.rowToEdit.title.en,
            es: this.rowToEdit.title.es
          }
          this.station.layout[this.rowIndexToEdit].automated = this.rowToEdit.automated
          this.station.layout[this.rowIndexToEdit].displayTitles = this.rowToEdit.displayTitles
          this.station.layout[this.rowIndexToEdit].type = this.rowToEdit.type
          this.station.layout[this.rowIndexToEdit].image_type = this.rowToEdit.image_type
          this.station.layout[this.rowIndexToEdit].items_desktop_count = this.rowToEdit.items_desktop_count
          this.station.layout[this.rowIndexToEdit].items_mobile_count = this.rowToEdit.items_mobile_count
          this.station.layout[this.rowIndexToEdit].items = this.rowToEdit.items
          this.station.layout[this.rowIndexToEdit].hide_app = this.rowToEdit.hide_app
          this.rowIndexToEdit = null
        } else {
          if (this.rowToEdit.type === 'ads') {
            this.station.layout.push({
              type: this.rowToEdit.type,
              automated: 'none',
              title: {
                en: this.rowToEdit.titleEnglish,
                es: this.rowToEdit.titleSpanish
              },
              bg_img: null,
              overlay_img: null,
              ipad_bg_img: null,
              ipad_overlay_img: null,
              web_image: null,
              url: null,
              items: []
            })
          } else {
            this.station.layout.push({
              type: this.rowToEdit.type,
              automated: this.rowToEdit.automated,
              title: {
                en: this.rowToEdit.title.en,
                es: this.rowToEdit.title.es
              },
              items: [],
              displayTitles: this.rowToEdit.displayTitles,
              image_type: 'square',
              items_desktop_count: this.rowToEdit.items_desktop_count,
              items_mobile_count: this.rowToEdit.items_desktop_count
            })
          }
        }

        this.resetRowToEdit()
        this.$refs.editRow.hide()
      },
      resetRowToEdit () {
        this.rowIndexToEdit = null
        this.rowToEdit.type = 'carousel' // 'cards' || 'grid'
        this.rowToEdit.title.es = 'Spanish Title'
        this.rowToEdit.title.en = 'English Title'
        this.rowToEdit.automated = 'none'
        this.rowToEdit.displayTitles = false
        this.rowToEdit.items_desktop_count = 4
        this.rowToEdit.items_mobile_count = 2
        this.rowToEdit.image_type = 'square'
        this.rowToEdit.items = []
      },
      checkCardSizes () {
        if (this.rowIndexToEdit && (this.rowToEdit.items.length > 0)) {
          let valid = true

          if (this.rowToEdit.image_type === 'square') {
            for (let index = 0; index < this.rowToEdit.items.length; index++) {
              const item = this.rowToEdit.items[index]
              if (!item.img[0]['750x750'] || item.img[0]['750x750'] === '') {
                valid = false
                break
              }
            }

            if (!valid) {
              this.rowToEdit.image_type = 'landscape'
              return this.$notify({
                group: 'notifications',
                title: 'All cards should have 750x750px images',
                text: '',
                type: 'error'
              })
            }
          }

          if (this.rowToEdit.image_type === 'landscape') {
            for (let index = 0; index < this.rowToEdit.items.length; index++) {
              const item = this.rowToEdit.items[index]
              if (!item.img[0]['1920x850'] || item.img[0]['1920x850'] === '') {
                valid = false
                break
              }
            }

            if (!valid) {
              this.rowToEdit.image_type = 'square'
              return this.$notify({
                group: 'notifications',
                title: 'All cards should have 1920x850px images',
                text: '',
                type: 'error'
              })
            }
          }
        }
      },
      handleCancel () {
        this.resetRowToEdit()
      },
      isRelease (releaseDate) {
        return moment(releaseDate).format() > this.todayDate
      },
      uploadAdsImage (event, targetWidth, targetHeight, targetProperty, rowIndex) {
        let input = event.target
        let regexImage = /\.(jpe?g|png)$/i

        if (targetProperty === 'overlay_img' || targetProperty === 'ipad_overlay_img') {
          regexImage = /\.(png)$/i
        }

        if (input.files && input.files[0]) {
          if (regexImage.test(input.files[0].name)) {
            let reader = new FileReader()
            reader.readAsDataURL(input.files[0])

            reader.onload = (e) => {
              const URL = window.URL || window.webkitURL
              const image = new Image()
              image.src = URL.createObjectURL(input.files[0])
              image.onload = () => {
                let height = image.height
                let width = image.width

                if (width !== targetWidth || height !== targetHeight) {
                  this.$notify({
                    group: 'notifications',
                    title: `Image size allowed is ${targetWidth}x${targetHeight}px :(`,
                    text: '',
                    type: 'error'
                  })

                  return false
                } else {
                  this.formData = new FormData()
                  this.formData.append('image', input.files[0])

                  this.$http.post('/locations/image',
                    this.formData,
                    {
                      headers:
                        {
                          'Content-Type': 'multipart/form-data'
                        }
                    }
                  )
                    .then((res) => {
                      if (res.success) {
                        this.station.layout[rowIndex][targetProperty] = res.data[0]

                        this.$notify({
                          group: 'notifications',
                          title: 'Image uploaded successfully :)',
                          text: '',
                          type: 'success'
                        })
                      } else {
                        this.$notify({
                          group: 'notifications',
                          title: `${res.message} :(`,
                          text: '',
                          type: 'error'
                        })
                      }

                      this.clearFormData(this.formData)
                    }).catch((err) => {
                      console.log(err)
                      this.clearFormData(this.formData)
                    })
                }
              }
            }
          } else {
            this.station.layout[rowIndex][targetProperty] = null

            if (targetProperty === 'overlay_img' || targetProperty === 'ipad_overlay_img') {
              return this.$notify({
                group: 'notifications',
                title: 'Image file should be PNG format :(',
                text: '',
                type: 'error'
              })
            }

            this.$notify({
              group: 'notifications',
              title: 'Image file should be JPG or PNG format :(',
              text: '',
              type: 'error'
            })
          }
        }
      },
      clearFormData () {
        this.formData = new FormData()
      },
      confirmRemoveRow (index) {
        this.$refs.confirmRemoveRow.show()
        this.rowIndexToDelete = index
      },
      removeRow () {
        this.station.layout.splice(this.rowIndexToDelete, 1)
      },
      getPodcasts () {
        return this.$http.get('/podcasts', {params: {limit: 50, page: 1}})
      },
      changeStatus (podcast, index) {
        if (this.podcasts[index].selected) {
          this.podcasts[index].selected = false

          if (this.station.podcasts.length) {
            this.station.podcasts.forEach((podcastID, $index) => {
              if (podcastID === podcast._id) {
                this.station.podcasts.splice($index, 1)
              }
            })
          }
          return
        }
        this.podcasts[index].selected = true
        this.station.podcasts.push(podcast._id)
      },
      getLivestreams () {
        return this.$http.get('/livestreams')
      },
      changeStatusLivestreams (livestream, index) {
        if (this.livestreams[index].selected) {
          this.livestreams[index].selected = false

          if (this.station.livestreams.length) {
            this.station.livestreams.forEach((livestreamID, $index) => {
              if (livestreamID === livestream._id) {
                this.station.livestreams.splice($index, 1)
              }
            })
          }
          return
        }
        this.livestreams[index].selected = true
        this.station.livestreams.push(livestream._id)
      },
      logoUploaded (data, res) {
        this.logo.url = res.data[0]
        this.station.logo_web_square_image = this.logo.url
      },
      carplayLogoUploaded(data, res){
        this.carplayLogo.url = res.data[0]
        this.station.carplayLogo.url = this.carplayLogo.url
      },
      playerMobileUpload (data, res) {
        this.playerMobile.url = res.data[0]
        this.station.player.mobile = this.playerMobile.url
      },
      playerTabletUpload (data, res) {
        this.playerTablet.url = res.data[0]
        this.station.player.tablet = this.playerTablet.url
      },
      phonePlayerUpload (data, res) {
        this.phonePlayer.url = res.data[0]
        this.station.player.phone = this.phonePlayer.url
      },
      variableLogoUpload (data, res) {
        this.variableLogo.url = res.data[0]
        this.station.logo.url = this.variableLogo.url; station.data.logo.url
      },
      fieldUpdated(data){
        this.variableLogo.height= data.height
        this.variableLogo.width= data.width
        this.station.logo.width = parseInt(this.variableLogo.width)
        this.station.logo.height = parseInt(this.variableLogo.height)
      },
      svgUploaded (url) {
        this.station.svg = url
        this.svg.url = url
      },
      playerBackgroundUpload (data, res) {
        this.webTopPlayerBackground.url = res.data[0]
        this.station.player.webTopBackground = this.webTopPlayerBackground.url
      },
      showCancelConfirm() {
        this.$refs.confirmCancelModal.show()
      },
      cancelAction() {
        this.$refs.confirmCancelModal.hide()
        this.$router.push('/admin/stations')
      },
      openLightBoxModal () {
        if (this.station.lightbox) {
          this.lightboxPublished = this.station.lightbox.published

          if(this.station.lightbox.card) {
            this.$http.get(`/cards/${this.station.lightbox.card.id}`)
                .then((res) => {
                  this.lightboxSelectedCard = res.data
                })
                .catch((err) => {
                  console.log(err)
                })
          }
        }


        this.searchWeblinkCards()
        this.$refs.modalLightBox.show()
      },
      closeLightBoxModal () {
        this.clearLightBoxData()
        this.$refs.modalLightBox.hide()
      },
      clearLightBoxData() {
        this.lightboxCards = []
        this.lightboxQueryCards = null
        this.lightboxSelectedCard = null
      },
      searchWeblinkCards () {
        const params = {
          type: 'contest',
          size: 'all',
          page_size: 50
        }

        if (this.lightboxQueryCards) {
          params.q = this.lightboxQueryCards
        }

        this.$http.get('/cards', { params })
            .then((res) => {
              this.lightboxCards = res.data
            })
            .catch((err) => {
              console.log(err)
            })
      },
      selectLightboxCard(card) {
        this.lightboxSelectedCard = card
      },
      updateStationLightBox($event) {
        if (!this.lightboxSelectedCard) {
          $event.preventDefault()
          return this.$notify({
            group: 'notifications',
            title: 'You must select a card',
            text: '',
            type: 'error'
          })
        }

        if(!this.lightboxSelectedCard.img || this.lightboxSelectedCard.img.length === 0 || !this.lightboxSelectedCard.img[0]['1920x850']) {
          $event.preventDefault()
          return this.$notify({
            group: 'notifications',
            title: 'The selected card must have a 1920x850 image',
            text: '',
            type: 'error'
          })
        }

        this.$set(this.station, 'lightbox', {
          card: {
            id: this.lightboxSelectedCard._id,
            type: this.lightboxSelectedCard.type
          },
          published: this.lightboxPublished
        })

        this.clearLightBoxData()
      },
      formatDate (date) {
        return moment(date).format('MMMM Do YYYY \\at HH:mm:ss A')
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .card-image video {
    width: 100%;
  }
  .web-ads {
    margin-top: 20px;
  }
  .controls-wrapper ul {
    max-width: 100% !important;
  }

  .model-buckets {
    article {
      display: inline-block;
      max-width: 120px;
      vertical-align: top;
      padding: 5px;
    }
    img {
      max-width: 100%;
    }
  }
  hr {
    margin-top: 0;
  }
  .controls-wrapper ul {
    max-width: 775px;
  }

  .cards-type-playlist .controls-wrapper ul {
    margin-bottom: 0 !important;
  }

  .station-content-wrapper {
    padding: 15px 0;
  }

  input.inline-checkbox {
    display: inline !important;
    width: auto !important;
    height: auto !important;
  }

  .cards-type-playlist {
    .header {
      margin-bottom: 60px;
    }
    .typeahead-wrapper {
      justify-content: flex-end;
    }
    .custom-file {
      margin-bottom: 15px;
    }

    .wrapper-btn{
      display: flex;
      justify-content: flex-end;
    }

    .card-btn {
      width: auto;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 12px;
      text-transform: uppercase;
    }

    .btn-add {
      margin-right: 10px;
    }

    .btn-modal {
      height: 45px;
    }
  }

  .card-image img{
    width: 100%;
    margin-bottom: 10px;
  }

  ul.playlistUpdated-list{
    list-style-type: none;
    overflow-y: scroll;
    max-height: 400px;
    padding: 3px;
  }

  ul.playlistUpdated-list .playlist-title{
    font-size: 12px;
    font-weight: bold;
  }

  ul.playlistUpdated-list .playlist-tracks{
    font-size: 10px;
    font-weight: bold;
  }

  ul.playlistUpdated-list li {
    list-style-type: none;
    display: flex;
    background-color: #fff;
    margin-bottom: 10px;
    height: 60px;
    align-items: center;
    border: 1px solid #eaeaea;
    cursor: pointer;
  }

  ul.playlistUpdated-list li.selected {
    background-color: #eaeaea;
  }

  ul.playlistUpdated-list li .playlists-list-card-img {
    margin-right: 15px;
  }

  ul.playlistUpdated-list li .playlists-list-card-img img{
    height: 60px;
    width: auto;
  }

  .station-image img {
    width: 100%;
  }

  .modal-wrapper label{
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px;
  }

  #editRow ul {
    margin-left: 2em;
  }

  .btn-program {
    padding: 0;
    font-size: 14px;
    width: 130px;
    line-height: 44px;
    text-align: center;
    background: #f2494a;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    border-radius: 0;
  }

  .btn-program.btn-delete {
    max-width: 45px;
  }

  .btn-program:hover,
  .btn-program.btn-delete:hover {
    background-color: #f2494a;
  }

  .tab-pane {
    padding: 20px 0;
  }

  .tab-pane input {
    height: 45px;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 15px;
    border: 1px solid #eaeaea;
  }

  .tab-pane label {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .tab-title{
    color: #2c3e50 !important;
  }

  .tab-title.tab-title-active {
    font-weight: bold;
  }

  .station-row-wrapper {
    display: flex;
    flex: 1;
    margin-bottom: 10px;
  }

  .station-row-wrapper input{
    margin-bottom: 0;
  }

  .update-station{
    float: right;
    margin-bottom: 20px;
  }

  .station-edit-component .page-content {
    margin-top: 115px;
  }

  .station-edit-component .page-header ul{
    margin-bottom: 0 !important;
  }

  .tab-content > .active {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #dee2e6;
    border-top: none;
  }

  .duplicate-program p {
    margin-bottom: 10px;
  }

  .duplicate-program ul {
    list-style-type: none;
  }

  .duplicate-program ul li {
    cursor: pointer;
    font-weight: bold;
    background-color: #ffffff;
    padding: 1em;
    margin: 10px 0;
    border: 1px solid #d4d4d4;
  }

  .duplicate-program ul li.selected {
    background-color: #bcf7bc;
  }

  .station-time-img {
    max-height: 100px;
  }

  .bucket-image {
    cursor: pointer;
  }

  .model-bucket-image img {
    max-width: 100%;
  }

  /*********************** Cards Styles *************************************/

  .layout-picker{
    background-color: #fff;
    border: 1px solid #eaeaea;
  }

  .layout-picker .card-area {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 20px 0 20px;
  }

  .layout-picker .cards-right-outer{
    height: -moz-calc(100vh - 350px);
    height: -webkit-calc(100vh - 350px);
    height: calc(100vh - 350px);
    overflow: scroll;
  }

  .layout-picker .cards-wrapper .card-area.cards-right{
    padding-bottom: 20px;
  }

  .layout-picker .card-wrapper{
    /* border:1px solid #eaeaea; */
    max-width: 32%;
    max-width: 31%;
    margin-right: 2%;
    font-size: 10px;
    font-weight: bold;
    align-self: start;
    background-color: #fff;
    cursor: pointer;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.10), 0 1px 2px rgba(0,0,0,0.20);
  }

  .layout-picker .card-wrapper .card-title{
    padding: 10px;
    margin-bottom: 0;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .layout-picker .cards-wrapper {
    padding: 15px 20px;
  }

  .layout-picker .card-wrapper .card-img img{
    max-width: 100%
  }
  .layout-picker .cards-wrapper .card-type {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .layout-picker .cards-wrapper .card-type .filterBy{
    font-size: 12px;
    font-weight: bold;
    margin-right: 15px;
    text-transform: uppercase;
  }

  .layout-picker .cards-wrapper .card-type select, .cards-wrapper .card-type input {
    height: 45px;
    border-radius: 0;
    border: 1px solid #eaeaea;
  }

  .layout-picker .cards-wrapper .card-type select {
    min-width: 110px;
    flex: 1;
    margin-right: 10px;
  }

  .layout-picker .cards-wrapper .card-type select:last-child {
    margin-right: 0;
  }

  .layout-picker .cards-wrapper .card-type input {
    padding: 0 15px;
    margin-right: 0;
    width: 100%;
  }

  .layout-picker .cards-wrapper .card-area{
    padding: 0;
  }

  .layout-picker .search-wrapper {
    display: flex;
    flex: 1;
    margin-bottom: 10px;
  }

  .layout-picker .search-wrapper input{
    flex: 1;
    padding: 0 10px;
  }

  .layout-picker .search-wrapper .btn-search-card {
    height: 45px;
    background: #f2494a;
    border-radius: 0;
    color: #fff;
    line-height: 32px
  }

  /*********************** Row Styles *************************/
  .card-wrapper {
    max-width: 31%;
  }
  .card-wrapper .card-title {
    padding: 10px;
    margin-bottom: 0;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
    font-weight: bold;
  }

  .station-area-outer {
    height: -moz-calc(100vh - 200px);
    height: -webkit-calc(100vh - 200px);
    height: calc(100vh - 200px);
    overflow: scroll;
    padding: 20px 10px 20px 3px;
  }

  .row-wrapper{
    padding: 15px 0 10px;
    margin-bottom: 40px;
    position: relative;
    background-color: #ffffff;
    min-height: 80px;
    border: 1px solid #eaeaea;
  }

  .row-wrapper:last-child{
    margin-bottom: 20px;
  }

  .row-title {
    position: absolute;
    top: -15px;
    left: 15px;
    background-color: #fff;
    padding: 5px 20px;
    border: 1px solid #eaeaea;
    font-weight: bold;
    font-size: 12px;
  }

  .row-wrapper .card-area {
    height: auto;
    box-shadow: none;
    min-height: 80px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 15px 20px 0 20px;
  }

  .row-wrapper .card-area.ads-section {
    padding: 0;
    background-color: #f7f7f7;
    margin-top: 20px;
    border: 1px dashed #afafaf;
  }

  .row-wrapper .card-area.ads-section  .message {
    margin-top: 25px;
    margin-left: 25px;
  }

  .row-wrapper .card-area.ads-section .card-wrapper {
    margin: 0;
  }

  .card-wrapper .card-img img{
    max-width: 100%
  }

  .row-wrapper .card-area .card-wrapper {
    width: 100px;
    margin-bottom: 10px;
    margin-right: 5px;
    position: relative;
    box-shadow: 0 1px 3px rgba(0,0,0,0.10), 0 1px 2px rgba(0,0,0,0.20);
  }

  .row-wrapper .card-area .card-wrapper.featured{
    width: 135px
  }

  .row-wrapper .row-type{
    position: absolute;
    top: -15px;
    right: 10px;
    background: #fff;
    border: 1px solid #eaeaea;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    text-transform: capitalize;
    width: 30px;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .row-wrapper .row-type img{
    width: 100%;
  }

  .row-wrapper .remove-row{
    position: absolute;
    right: 10px;
    bottom: 5px;
    text-align: center;
    line-height: 20px;
  }

  .row-wrapper .remove-row.remove-row-card{
    top: -10px;
    right: -10px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    z-index: 1;
    display: none;
    background: #fff;
    border: 1px solid #eaeaea;
  }

  .row-wrapper .card-area .card-wrapper:hover .remove-row-card,
  .row-wrapper .card-area .card-wrapper:hover .duplicate-card {
    display: block
  }

  .row-wrapper .card-area .card-wrapper .duplicate-card:hover {
    background-color: rgba(242, 73, 74, 1);
  }

  .add-row {
    width: 100%;
    background-color: #eaeaea;
    box-shadow: 0 1px 3px rgba(0,0,0,0.10), 0 1px 2px rgba(0,0,0,0.20);
    text-align: center;
    height: 60px;
    line-height: 60px;
    font-weight: bold;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
  }

  /************************* Ads row styles *******************/

  .ads-row {
    padding: 20px;
  }

  .ads-form img {
    max-width: 100%;
    height: 200px;
  }

  .ads-image-wrapper {
    padding: 5px;
    border: 1px solid #e8e8e8;
    background-color: #e8e8e8;
  }

  .ads-label {
    font-size: 12px;
  }

  .podcasts {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 50x;
    cursor: pointer;
  }

  .podcasts.selected {
    background-color: #dad9d9;
  }

  .podcasts img {
    width: 100%;
    max-width: 120px;
    margin-right: 20px;
  }

  .podcasts .title {
    font-size: 14px;
    font-weight: bold;
  }

  .modal-wrapper-podcasts {
    height: 500px;
    overflow: scroll;
  }

</style>
