import { DateTime as LuxonDateTime } from 'luxon'

export default class CardTypeCommon {
  constructor (context) {
    this.context = context

    this.configuration = {
      minDatetime: LuxonDateTime.local().toISO(),
      maxDatetime: LuxonDateTime.fromObject({ year: 2025, month: 12, day: 31, hour: 0, minute: 0 }).toISODate()
    }

    this.uploads = {
      video: [
        {
          title: 'Featured Video Card',
          description: 'Video card used for iOS/Android featured carousel',
          url: null,
          placeholder: 'Video 1000 x 1100px',
          loading: false,
          width: 1000,
          height: 1100
        }
      ],
      images: [
        {
          title: 'Row Square Image',
          description: 'Image used for the rows on LaMusica iOS/Android and square items on mobile web',
          file: null,
          url: '',
          key: '750x750',
          placeholder: 'Image 750 x 750px',
          loading: false,
          width: 750,
          height: 750,
          columns: 6
        },
        {
          title: 'Featured Image Card',
          description: 'Image card used on the featured carousel of LaMusica iOS/Android and desktop web',
          file: null,
          url: '',
          key: '1000x1100',
          placeholder: 'Image 1000 x 1100px',
          loading: false,
          width: 1000,
          height: 1100,
          columns: 6
        },
        {
          title: 'Portrait Image',
          description: 'Portrait Images are long images used in all platforms in lamusica.',
          file: null,
          url: '',
          key: '1080x1920',
          placeholder: 'Image 1080 x 1920px',
          loading: false,
          width: 1080,
          height: 1920,
          columns: 6
        },
        {
          title: 'Thumbnail Image',
          description: 'Currently not used anywhere, it will be used as another layout option for LaMusica web rows',
          file: null,
          url: '',
          key: '1296x560',
          placeholder: 'Image 1296 x 560px',
          loading: false,
          width: 1296,
          height: 560,
          columns: 12
        },
        {
          title: 'Website Featured Slider Image',
          description: 'Image currently used exclusively on LaMusica web for rotators, should be center aligned',
          file: null,
          url: '',
          key: '1920x850_web',
          placeholder: 'Image 1920 x 850px',
          loading: false,
          width: 1920,
          height: 850,
          columns: 12
        },
        {
          title: 'TV Featured Image',
          description: 'Image currently used exclusively on LaMusica web for TV apps, should be right aligned',
          file: null,
          url: '',
          key: '1920x850',
          placeholder: 'Image 1920 x 850px',
          loading: false,
          width: 1920,
          height: 850,
          columns: 12
        }
      ]
    }
  }
  setCardDefaultValues () {
    const card = this.context.card
    const uploadImageSizes = this.uploads.images
    for (const imageSet of card.img) {
      if (!imageSet) {
        continue
      }
      for (const key in imageSet) {
        const value = imageSet[key] || ''
        for (const size of uploadImageSizes) {
          if (key === size.key) {
            size.url = value
          }
        }
      }
    }

    if (card.video && card.video.length > 0 && card.video[0]['1000x1100']) {
      this.setVideoURLs({'1000x1100': card.video[0]['1000x1100']})
    }

    if (!card.available) {
      card['available'] = {
        start: this.configuration.minDatetime,
        end: this.configuration.maxDatetime
      }
    }
  }

  handleCardUpdate () {
    const $this = this.context
    $this.card.id = $this.card.id.toString()
    $this.$http.put('/cards', {card: $this.card}).then((res) => {
      if (res.success) {
        $this.$notify({
          group: 'notifications',
          title: 'Card updated successfully :)',
          text: '',
          type: 'success'
        })
        $this.$router.push('/admin/cards')
      } else {
        $this.$notify({
          group: 'notifications',
          title: 'Images are required :)',
          text: '',
          type: 'error'
        })
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  validateCardBeforeSave () {
    const $this = this.context
    const uploadImageSizes = this.uploads.images

    if ($this.card.available.start >= $this.card.available.end) {
      $this.$notify({
        group: 'notifications',
        title: '<code>Start Date</code> should be before <code>End date</code>',
        text: '',
        type: 'error'
      })
      return false
    }

    $this.card.img = []

    const image = {}
    for (const size of uploadImageSizes) {
      image[size.key] = size.url || ''
    }
    $this.card.img.push(image)

    const imageURLs = uploadImageSizes.filter((imageSize) => {
      return imageSize.url
    })

    if (imageURLs.length < 1) {
      $this.$notify({
        group: 'notifications',
        title: 'At least one image is required :(',
        text: '',
        type: 'error'
      })
      return false
    }

    $this.card.video = []
    $this.card.video[0] = {
      '750x750': null,
      '1000x1100': $this.video.url || null
    }

    return true
  }

  setImageURLValues (keyMap) {
    for (const imageSize of this.uploads.images) {
      if (keyMap[imageSize.key]) {
        imageSize.url = keyMap[imageSize.key]
      }
    }
  }

  setVideoURLs (sizeMap) {
    for (const videoSize of this.uploads.video) {
      const sizeKey = `${videoSize.width}x${videoSize.height}`
      const fileURL = sizeMap[sizeKey]
      if (fileURL) {
        videoSize.url = fileURL
        videoSize.html = `<video controls><source src="${fileURL}" type="video/mp4"></video>`
      }
    }
  }

  handleImageUpload ($event, size, index) {
    const input = $event.target
    const $this = this.context
    const file = input.files && input.files[0]
    if (!file) {
      return
    }
    const hasValidExtension = /\.(jpe?g|png|gif)$/i.test(file.name)
    if (!hasValidExtension) {
      return $this.$notify({
        group: 'notifications',
        title: 'Image should be jpg :(',
        text: '',
        type: 'error'
      })
    }

    if(file.size > 1000000){
      return $this.$notify({
        group: 'notifications',
        title: 'Image size must be less than 1MB :(',
        text: '',
        type: 'error'
      })
    }

    const URL = window.URL || window.webkitURL
    const image = new Image()

    image.src = URL.createObjectURL(file)
    image.onload = () => {
      const height = image.height
      const width = image.width

      if (width !== size.width || height !== size.height) {
        $this.$notify({
          group: 'notifications',
          title: `Wrong image size, please try with another file and upload again :(`,
          text: `Required: ${size.width}x${size.height}px\n(You provided ${width}x${height})`,
          type: 'error'
        })

        size.file = null
        return
      }

      const formData = new FormData()
      formData.append(`file${index}`, file)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      size.loading = true
      $this.$http.post('/cards/image', formData, config).then((res) => {
        if (res.success) {
          size.url = res.data[0]
        } else {
          $this.$notify({
            group: 'notifications',
            title: 'Image file is required :)',
            text: '',
            type: 'error'
          })
        }
      }).catch((err) => {
        console.log(err)
        $this.$notify({
          group: 'notifications',
          title: 'There was an error uploading this image :)',
          text: '',
          type: 'error'
        })
      }).finally(() => {
        size.loading = false
      })
    }
  }

  handleVideoUpload (event, video) {
    const input = event.target
    const $this = this.context
    const formData = new FormData()
    const file = input.files && input.files[0]
    if (!file) {
      return
    }

    const hasValidExtension = /\.(mp4)$/i.test(file.name)
    if (!hasValidExtension) {
      return $this.$notify({
        group: 'notifications',
        title: 'Video should be mp4 format :(',
        text: '',
        type: 'error'
      })
    }

    const fileSize = file.size / 1024 / 1024
    const isTooLargeUpload = fileSize > 3
    if (isTooLargeUpload) {
      return $this.$notify({
        group: 'notifications',
        title: 'Video File size exceeds 3 MB :)',
        text: '',
        type: 'error'
      })
    }

    formData.append('file', file)

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    video.loading = true
    $this.$http.post('/cards/video', formData, config).then((res) => {
      if (res.success) {
        video.url = res.data[0]
      } else {
        $this.$notify({
          group: 'notifications',
          title: res.message,
          text: '',
          type: 'error'
        })
      }
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      video.loading = false
    })
  }

  handleRemoveVideoCard () {
    const $this = this.context
    $this.video.html = null
    $this.video.url = null
    $this.video.placeholder = '1000 x 1100px'
    $this.$refs.videoInput.reset()
  }
}
