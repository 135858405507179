<template>
  <div class="podcast-item-wrapper">
    <div class="item-header">
      <div class="row align-items-center">
        <div class="col-12 col-md-6">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 pl-0">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'podcast-all' }">Podcasts</router-link>
              </li>
              <li class="breadcrumb-item active">Download Audio</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-7">
        <h5 class="mt-2 text-muted">Downloaded files</h5>
        <div class="row align-items-center mt-3">
          <div class="col-10">
            <div class="search-wrapper">
              <b-input-group size="sm">
                <b-form-input placeholder="Search downloaded files" v-model="searchQuery.local.text" @input="searchByText('local', true)" :disabled="loading.local"></b-form-input>
                <b-input-group-append>
                  <button class="btn" :disabled="loading.local">
                    <i class="fas fa-search"></i>
                  </button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
          <div class="col-2 text-right">
            <button class="btn btn-danger btn-sm font-weight-bold" @click="search('local', true)" :disabled="loading.local">
              <i class="fas fa-sync" :class="{'fa-spin': loading.local}"></i>
              Reload
            </button>
          </div>
        </div>
        <hr class="mt-3"/>

        <div class="row mt-2 align-items-center" v-if="files && files.length > 0">
          <div class="col font-weight-bold">
            {{ pagination.total }} files found
          </div>
          <div class="col text-right">
            <div class="d-flex justify-content-end align-items-center">
              <div class="mr-1">
                <button class="btn btn-sm" :disabled="loading.local || pagination.current === 1" @click="prev">
                  <i class="fas fa-chevron-left"></i>
                </button>
              </div>
              <div>
                <span class="font-weight-bold"> {{ pagination.current }} / {{ pagination.pages }}</span>
              </div>
              <div class="ml-1">
                <button class="btn btn-sm" :disabled="loading.local || pagination.current >= pagination.pages"
                        @click="next">
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>


        <div class="row mt-2">
          <div class="col-md-12">
            <div class="loading-wrapper" v-if="loading.local">
              <LoaderInline/>
            </div>

            <div class="list pl-3" v-if="files.length > 0">
              <div class="row list-item mb-3 rounded" v-for="(file) in files" :key="file._id">
                <div class="col px-3 py-3">
                  <div class="title">{{ file.name }}</div>
                  <div class="date">
                    <strong>Download Date:</strong> {{ formatFileDate(file.created_at) }}
                    <b-badge :variant="getStatusVariant(file.status)" class="p-1 text-uppercase">{{ file.status }}</b-badge>
                    <b-badge variant="info" class="p-1 text-uppercase" v-if="file.status === 'failed' && file.retry_attempts >= 1">Contact System Administrator</b-badge>
                  </div>
                  <div class="pt-2 text-center text-md-left">
                    <a class="btn btn-danger btn-sm" :href="file.file_url" target="_blank" v-if="file.status === 'ready'">
                      <i class="fas fa-download"></i>
                      Download
                    </a>

                    <button class="btn btn-danger btn-sm" v-if="file.status === 'failed' && file.retry_attempts < 1" @click="retry(file)">
                      <i class="fas fa-sync"></i>
                      Retry
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <b-alert class="text-center" show v-else>Cannot find videos matching with the search criteria.</b-alert>
          </div>
        </div>
      </div>
      <div class="col-5">
        <h5 class="mt-2 text-muted">Mediastream Videos</h5>
        <div class="row align-items-center mt-3">
          <div class="col-12">
            <div class="search-wrapper">
              <b-input-group size="sm">
                <b-form-input placeholder="Search videos on Mediastream" v-model="searchQuery.remote.text"
                              @input="searchByText('remote', true)" :disabled="loading.remote"></b-form-input>
                <b-input-group-append is-text>
                  <b-form-checkbox switch class="mr-n2 mb-n1 pr-2" v-model="searchQuery.remote.searchById"
                                   @change="search('remote', true)" :disabled="loading.remote">
                    Search by ID
                  </b-form-checkbox>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </div>
        <hr class="mt-3"/>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="loading-wrapper" v-if="loading.remote">
              <LoaderInline/>
            </div>
            <div class="list pl-3 pr-3" v-if="remoteItems.length > 0">
              <div class="row list-item mb-3 py-3 align-items-center rounded" v-for="(item) in remoteItems" :key="item.id">
                <div class="col-2 pr-0">
                  <img :src="item.image_url" alt="" class="img-fluid rounded"/>
                </div>
                <div class="col-8">
                  <div class="title text-truncate">{{ item.file_name }}</div>
                  <div class="date">
                    <strong>Download Date:</strong> {{ formatFileDate(item.date_modified) }}
                  </div>
                </div>
                <div class="col-2 text-center">
                  <button class="btn btn-danger btn-sm" @click="extractAudio(item)">
                    <i class="fas fa-plus"></i>
                    Add
                  </button>
                </div>
              </div>
            </div>
            <template v-else>
              <b-alert v-if="searchQuery.remote.text" show class="text-center">Cannot find videos matching with the search criteria.</b-alert>
              <b-alert v-else show class="text-center">Start search to retrieve videos from Mediastream</b-alert>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EpisodeStatus from "@/components/admin/podcasts/episode-status.vue";
import moment from "moment-timezone";
import LoaderInline from "@/components/share/LoaderInline.vue";

export default {
  name: 'DownloadAudio',
  components: { LoaderInline, EpisodeStatus },
  data () {
    return {
      files: [],
      remoteItems: [],
      searchTimeOut: null,
      searchQuery: {
        local: {
          text: ''
        },
        remote: {
          text: '',
          searchById: true
        }
      },
      loading: {
        local: false,
        remote: false
      },
      pagination: {
        limit: 10,
        pages: 1,
        current: 1,
        total: 1
      }
    }
  },
  mounted () {
    this.searchLocalFiles(true)
  },
  methods: {
    searchByText (target) {
      clearTimeout(this.searchTimeOut)
      this.searchTimeOut = setTimeout(() => {
        this.search(target, true)
      }, 500)
    },
    search (target, override) {
      if (target === 'remote') {
        this.searchIRemoteVideos(override)
      } else {
        this.searchLocalFiles(true)
      }
    },
    searchLocalFiles (override = false) {
      const params = {
        limit: this.pagination.limit,
        page: this.pagination.current
      }

      if(this.searchQuery.local.text.length > 0) {
        params.query = this.searchQuery.local.text
      }

      this.loading.local = true

      this.$http.get(`/audio-extraction/search/local`, { params })
          .then((response) => {
            if (response.success) {
              this.files = override ? response.data.items : [...this.files, ...response.data.items]
              this.pagination = response.data.pagination
            } else {
              this.$notify({
                group: 'notifications',
                title: 'Error',
                text: response.message,
                type: 'error'
              })
            }
          })
          .catch((error) => {
            console.log(error)
            this.files = []
          })
          .finally(() => {
            this.loading.local = false
          })
    },
    searchIRemoteVideos (override = false) {
      if (this.searchQuery.remote.text.length < 3) {
        if (this.searchQuery.remote.text === '') {
          this.remoteItems = []
        }

        return false
      }

      const params = {}

      if (this.searchQuery.remote.searchById) {
        params.id = this.searchQuery.remote.text
      } else {
        params.query = this.searchQuery.remote.text
      }

      this.loading.remote = true

      this.$http.get(`/audio-extraction/search/remote`, { params })
          .then((response) => {
            if (response.success) {
              this.remoteItems = override ? response.data.items : [...this.remoteItems, ...response.data.items]
            } else {
              this.$notify({
                group: 'notifications',
                title: 'Error',
                text: response.message,
                type: 'error'
              })
            }
          })
          .catch((error) => {
            console.log(error)
            this.remoteItems = []
          })
          .finally(() => {
            this.loading.remote = false
          })
    },

    prev() {
      this.pagination.current --
      this.searchLocalFiles(true)
    },

    next() {
      this.pagination.current ++
      this.searchLocalFiles(true)
    },

    async extractAudio (item) {
      try {
        const response = await this.$http.post('/audio-extraction/extract-audio', item)
        if(response.success) {
          this.search('local', true)
        } else {
          this.$notify({
            group: 'notifications',
            title: 'Error',
            text: response.message,
            type: 'error'
          })
        }
      } catch (error) {
        this.$notify({
          group: 'notifications',
          title: 'Error',
          text: error.message,
          type: 'error'
        })
      }
    },

    async retry (item) {
      try {
        const response = await this.$http.post(`/audio-extraction/${item._id}/retry`)
        if (response.success) {
          this.search('local', true)
        } else {
          this.$notify({
            group: 'notifications',
            title: 'Error',
            text: response.message,
            type: 'error'
          })
        }
      } catch (error) {
        this.$notify({
          group: 'notifications',
          title: 'Error',
          text: error.message,
          type: 'error'
        })
      }
    },

    getStatusVariant (status) {
      const variants = {
        ready: 'success',
        processing: 'warning',
        failed: 'danger'
      }

      return variants[status]
    },
    formatFileDate (date) {
      return moment(date).format('MMMM Do, YYYY [at] h:mm a')
    }
  }
}
</script>
<style scoped lang="scss">
.loading-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(247, 247, 247, 0.95);
  z-index: 1;

  .loader-inline {
    margin-top: calc(50vh - 230px);
  }
}

.list {
  .list-item {
    background-color: #fff;
    border: 1px solid #eaeaea;
  }
}
</style>
